import { t } from 'i18next';

export const exceededMaxCharacters = (max: number) =>
  t('common:common.form.errors.max_characters', { max: max });

export const minCharacters = (min: number) =>
  t('common:common.form.errors.min_characters', { min: min });

export const requiredField = () =>
  t('common:common.form.errors.required_field');

export const requiredAutocompleteField = () =>
  t('common:common.form.errors.required__autocomplete_field');

export const dateMustBeGreaterThan = () =>
  t('common:common.form.errors.date_error');

export const invalidEmailField = () =>
  t('common:common.form.errors.invalid_email_field');
export const validDateField = () =>
  t('common:common.form.errors.valid_date_field');

export const SingularPluralMonths = (month: number) =>
  t(month > 1 ? 'common:months.plural' : 'common:months.singular', {
    days: month
  });
export const SingularPluralDays = (day: number) =>
  t(day !== 1 ? 'common:days.plural' : 'common:days.singular', {
    days: day
  });

export const exceededMaxDigits = (max: number) =>
  t('common:common.form.errors.max_digits', { max: max });

export const numberGTZero = () => t('common:common.form.errors.gt_zero');
