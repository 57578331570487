import {
  Box,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingStepperIcon } from '../../../../../components/Icons/OnboardingStepperIcon';
import { ElephantIcon, StepStack, StyledScrollbar } from '../Stylings';

type Props = {
  activeStep: number;
  steps: string[];
};
const LeftSideStepper: FC<Props> = ({ activeStep, steps }) => {
  const { t } = useTranslation('common');

  return (
    <StyledScrollbar>
      <StepStack
        py={3}
        px={4}
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          sx={{
            height: '50%'
          }}
        >
          <Box pt={3}>
            <ElephantIcon />
          </Box>
          <Stack direction="column" rowGap={2} sx={{ color: 'white' }}>
            <Typography variant="h6" fontWeight="bold">
              {t('profile.onboarding.title')}
            </Typography>
            <Typography variant="body2" lineHeight="1.25" fontWeight="200">
              {t('profile.onboarding.description')}
            </Typography>
          </Stack>
        </Stack>

        <Stepper
          activeStep={activeStep-1}
          alternativeLabel
          connector={null}
          sx={{
            '& .MuiStep-alternativeLabel': {
              paddingRight: '0.25rem',
              paddingLeft: '0.25rem'
            }
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={OnboardingStepperIcon} />
            </Step>
          ))}
        </Stepper>
      </StepStack>
    </StyledScrollbar>
  );
};

export default LeftSideStepper;
