import { useQuery } from 'react-query';
import api from '../services/api';
import { COMMON_CACHE_KEYS } from './Common.constants';

const getCountriesList = async (onboarding?: boolean): Promise<Country[]> => {
  const { data } = await api.get(`/common/location`, {
    params: { onboarding }
  });
  return data;
};

const useCountriesList = (onboarding?: boolean): any => {
  return useQuery<Country[]>(
    [COMMON_CACHE_KEYS.countries],
    async () => getCountriesList(onboarding),
    { staleTime: 0, keepPreviousData: true }
  );
};

export default useCountriesList;
