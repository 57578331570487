import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Theme } from '@mui/system';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/Buttons/ActionButton';
import RHFAutocomplete from '../../../../components/Forms/inputs/RHF/RHFAutocomplete';
import { RHFNumberFormat } from '../../../../components/Forms/inputs/RHF/RHFNumberFormat';
import BankIcon from '../../../../components/Icons/BankIcon';
import FileUploadInput from '../../../../components/Inputs/FileUploadInput';
import useBanksList from '../../../../hooks/useBanksList';
import { bankAccountTypeChoices } from '../../../../utils/choices';
import useUpdateUser from '../../Profile/hooks/useUpdateUser';
import useUserDetail from '../../Profile/hooks/useUserDetail';
import { bankSchema } from './onboardingSchemas';
import { FormCard } from './Stylings';

type Props = {
  onNext: () => void;
  onOmitNext: () => void;
};

export default function BankFormStep({ onNext, onOmitNext }: Props) {
  const { t } = useTranslation('common');
  const atLeastSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const { mutate, isLoading: isSubmitting, isSuccess } = useUpdateUser();
  const { data: selectedUser, isLoading: profileLoading } = useUserDetail();
  const { data: bankResults } = useBanksList();
  const banks = bankResults?.results ?? [];

  const [bankCertificateFile, setBankCertificateFile] = useState<File | null>(
    null
  );

  const defaultValues = {
    bank: selectedUser?.bank ?? null,
    bank_account_type: selectedUser?.bank_account_type ?? null,
    bank_account_number: selectedUser?.bank_account_number ?? ''
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(bankSchema)
  });

  const onSubmit = (values: any) => {
    const newValues = {
      ...values,
      bank: values.bank?.id
    };

    const formData = new FormData();
    Object.entries<any>(newValues).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (bankCertificateFile)
      formData.append('bank_account_certificate_file', bankCertificateFile);

    mutate({
      userId: selectedUser?.id,
      userObj: newValues,
      updatedUser: formData
    });
  };

  useEffect(() => {
    if (isSuccess) onNext();
  }, [isSuccess, onNext]);

  useEffect(() => {
    if (selectedUser) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  if (profileLoading) {
    return <>{t('common.loading')}</>;
  }

  if (profileLoading) return <>{t('common.form.loading')}</>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction="column"
        justifyContent="center"
        rowGap={3}
        py={2}
        px={{
          xs: 2,
          md: 0
        }}
        borderRadius={{
          xs: '0.75rem',
          md: 0
        }}
        bgcolor={atLeastSm ? 'background.paper' : 'common.white'}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            {t('profile.onboarding.form.your_bank')}
          </Typography>
          <Typography variant="body2" color="grey.700" align="center">
            {t('profile.onboarding.form.your_bank_objective')}
          </Typography>
        </Stack>
        <FormCard>
          <Grid
            container
            spacing={3}
            p={{
              xs: 2,
              md: 4
            }}
          >
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom fontWeight="600">
                {t('profile.form.account_data')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFAutocomplete
                control={control}
                name="bank"
                error={errors.bank}
                textFieldProps={{
                  label: t('profile.bank_form.bank_label'),
                  required: true
                }}
                autocompleteProps={{
                  id: 'bank',
                  options: banks,
                  getOptionLabel: (option) => option.name,
                  isOptionEqualToValue: (option, value) =>
                    option.id === value.id ||
                    ('id' in value && 'name' in value),
                  renderOption: (props, option) => (
                    <li {...props}>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        columnGap={1}
                      >
                        <BankIcon bankName={option.name} />
                        <Typography variant="inherit">{option.name}</Typography>
                      </Stack>
                    </li>
                  )
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <RHFAutocomplete
                control={control}
                name="bank_account_type"
                error={errors.bank_account_type}
                textFieldProps={{
                  label: t('profile.bank_form.account_type_label'),
                  required: true
                }}
                autocompleteProps={{
                  id: 'bank_account_type',
                  options: bankAccountTypeChoices,
                  getOptionLabel: (option) =>
                    t(`profile.bank_form.${option.toLowerCase()}`)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <RHFNumberFormat
                control={control}
                name="bank_account_number"
                data-cy="bank_account_number"
                inputProps={{ maxLength: 25 }}
                label={t('profile.bank_form.account_number_label')}
                required
                error={errors.bank_account_number}
              />
            </Grid>
            <Grid item xs={12}>
              <FileUploadInput
                file={bankCertificateFile}
                handleFile={setBankCertificateFile}
                acceptedExtensions={['pdf']}
                placeholder={t('profile.bank_form.drag_file_invitation')}
              />
            </Grid>
          </Grid>
        </FormCard>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              {isSubmitting ? (
                <CircularProgress size={24} />
              ) : (
                <Button
                  variant="text"
                  fullWidth
                  size="large"
                  onClick={onOmitNext}
                  sx={{
                    color: 'action.active',
                    '&:hover': {
                      textDecoration: 'underline',
                      background: 'none',
                      color: 'action.active'
                    }
                  }}
                >
                  <Typography variant="inherit">
                    {t('common.form.omit')}
                  </Typography>
                </Button>
              )}
            </Grid>
            <Grid item mr={4}>
              <ActionButton
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                rightarrow={1}
                disabled={!isValid || isSubmitting || !bankCertificateFile}
              >
                <Typography variant="inherit">
                  {t('common.form.next')}
                </Typography>
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
}
