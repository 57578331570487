import { createContext, Dispatch, useContext, useState } from 'react';

type UserSignUp = {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string | null;
  phone_country_code?: string | null;
};

type UserContextInterface = {
  currentUser: AuthUser;
  setCurrentUser: Dispatch<any>;
  signUpUser: UserSignUp;
  setSignUpUser: Dispatch<any>;
};
const UserContext = createContext<UserContextInterface | null>(null);

export const UserProvider = ({ user, children }: any): any => {
  const [currentUser, setCurrentUser] = useState(user);
  const [signUpUser, setSignUpUser] = useState(user);

  return (
    <UserContext.Provider
      value={{ currentUser, setCurrentUser, signUpUser, setSignUpUser }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const useUser = () => useContext(UserContext);
