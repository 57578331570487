import { Button, Dialog, Stack, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DesktopInvitationFigure from '../../../../components/Figures/DesktopInvitationFigure';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const DesktopInvitationDialog: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation('common');

  const LeftDecorator = styled('div')(({ theme }) => ({
    position: 'absolute',
    height: '5rem',
    width: '5rem',
    border: '1px solid',
    borderColor: theme.palette.action.hover,
    borderRadius: '18.75rem',
    left: 0,
    transform: 'translate(-1rem, -1rem)',
    overflow: 'hidden'
  }));

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        zIndex: '1301 !important',
        overflow: 'hidden'
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Stack direction="column" alignItems="center" px={4} rowGap={4}>
          <Typography variant="h6" fontWeight="bold" align="center">
            {t('profile.onboarding.desktop_invitation.title')}
          </Typography>
          <Box>
            <LeftDecorator />
            <DesktopInvitationFigure />
          </Box>
          <Stack direction="row">
            <Typography variant="body2" align="center">
              {t('profile.onboarding.desktop_invitation.description_prefix')}{' '}
              <Typography
                component="span"
                variant="body2"
                color="action.active"
              >
                {t('profile.onboarding.desktop_invitation.recommendation')}
              </Typography>{' '}
              {t('profile.onboarding.desktop_invitation.description_suffix')}
            </Typography>
          </Stack>
          <Button variant="contained" size="large" onClick={handleClose}>
            <Typography variant="inherit">{t('common.ok')}</Typography>
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default DesktopInvitationDialog;
