import * as yup from 'yup';
import {
  exceededMaxCharacters,
  requiredAutocompleteField,
  requiredField
} from '../../../../utils/translations';

export const onboardingProfileSchema = yup.object().shape({
  firstname: yup.string().max(150).required(requiredField),
  lastname: yup.string().max(150).required(requiredField),
  identification_type: yup
    .string()
    .required(requiredAutocompleteField)
    .nullable(),
  identification_number: yup
    .string()
    .max(10, exceededMaxCharacters(10))
    .required(requiredField),
  sex: yup.string().required(requiredAutocompleteField).nullable(),
  city: yup
    .object()
    .shape({
      name: yup.string().required()
    })
    .required(requiredAutocompleteField)
    .nullable(),
  address: yup
    .string()
    .max(125, exceededMaxCharacters(125))
    .required(requiredField),
  phone: yup
    .string()
    .max(15, exceededMaxCharacters(15))
    .required(requiredField),
  country_code: yup.object().shape({
    code: yup.string(),
    countryName: yup.string(),
    countryPrefix: yup.string()
  })
});

export const bankSchema = yup.object().shape({
  bank: yup
    .object()
    .shape({
      name: yup.string().required()
    })
    .required(requiredField)
    .nullable(),
  bank_account_type: yup.string().required(requiredField).nullable(),
  bank_account_number: yup
    .string()
    .max(32, exceededMaxCharacters(32))
    .required(requiredField)
});

export const employmentSchema = yup.object().shape({
  labor_sector: yup
    .object()
    .shape({
      name: yup.string().required(requiredField)
    })
    .required(requiredField)
    .nullable(),
  work_area: yup
    .string()
    .max(125, exceededMaxCharacters(125))
    .required(requiredField)
});
