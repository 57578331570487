import { Dialog, Grid, useMediaQuery } from '@mui/material';
import { Theme, ThemeProvider } from '@mui/system';
import { useTour } from '@reactour/tour';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import mdTheme from '../../../../components/Theme';
import { useUser } from '../../../../contexts/UserContext';
import { numberGenericOptionGenerator } from '../../../../utils/generators';
import BankFormStep from './BankFormStep';
import DesktopInvitationDialog from './DesktopInvitationDialog';
import EmploymentFormStep from './EmploymentFormStep';
import ProfileFormStep from './ProfileFormStep';
import LeftSideStepper from './Steppers/LeftSideStepper';
import MobileTopStepper from './Steppers/MobileTopStepper';
import { StyledScrollbar } from './Stylings';

export default function OnboardingModal() {
  const user = useUser();
  const atLeastMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const atLeastSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const { search } = useLocation();
  const step = new URLSearchParams(search).get('step');
  const activeStep = Number(step);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const [betterOnDesktopModal, setBetterOnDesktopModal] =
    useState<boolean>(false);
  const steps = numberGenericOptionGenerator(3);
  const { setIsOpen: setReactTourOpen } = useTour();
  const hasCompletedOnboarding = localStorage.getItem(
    'has_completed_onboarding'
  );
  useEffect(() => {
    if (
      user?.currentUser &&
      localStorage.getItem('has_completed_onboarding') === 'false'
    )
      setOpenModal(true);
  }, [user?.currentUser]);

  useEffect(() => {
    if (!atLeastSm && hasCompletedOnboarding === 'false') {
      setBetterOnDesktopModal(true);
    }
    if (atLeastSm && hasCompletedOnboarding === 'false') {
      setBetterOnDesktopModal(false);
    }
  }, [atLeastSm, hasCompletedOnboarding]);

  useEffect(() => {
    if (
      user?.currentUser &&
      localStorage.getItem('has_completed_onboarding') === 'true' &&
      localStorage.getItem('has_completed_tour') === 'false'
    ) {
      setTimeout(() => {
        setReactTourOpen(true);
      }, 500);
    }
  }, [user?.currentUser, setReactTourOpen]);

  const handleNextStep = () => {
    navigate(`/onboarding?step=${activeStep + 1}`);
  };

  const handleProfileFormNext = () => {
    handleNextStep();
  };
  const handleBankFormNext = () => {
    handleNextStep();
  };

  const handleEmploymentFormNext = () => {
    localStorage.setItem('has_completed_onboarding', 'true');
    setOpenModal(false);
    setReactTourOpen(true);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Dialog
        open={openModal}
        fullScreen={!atLeastSm}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            overflow: 'hidden',
            zIndex: '1300 !important',
            // apply color blue when xs and white when md
            backgroundColor: atLeastSm ? 'background.paper' : 'action.active'
          }
        }}
      >
        {!atLeastSm && (
          <MobileTopStepper activeStep={activeStep} steps={steps} />
        )}
        <Grid
          container
          direction="row"
          justifyContent="center"
          columnSpacing={{
            md: 3,
            lg: 6,
            xl: 9
          }}
          p={3}
          height="80%"
          overflow="hidden"
        >
          {atLeastMd && (
            <Grid item xs={3} md={4} lg={3}>
              <LeftSideStepper activeStep={activeStep} steps={steps} />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={8}
            lg={9}
            pr={{
              lg: 3,
              xl: 6
            }}
            maxHeight="100%"
            sx={{
              ...(atLeastSm && { height: 'calc(100vh - 7.5rem)' })
            }}
          >
            <StyledScrollbar>
              {activeStep === 1 && (
                <ProfileFormStep onNext={handleProfileFormNext} />
              )}
              {activeStep === 2 && (
                <BankFormStep
                  onNext={handleBankFormNext}
                  onOmitNext={handleNextStep}
                />
              )}
              {activeStep === 3 && (
                <EmploymentFormStep
                  onNext={handleEmploymentFormNext}
                  onOmitNext={handleEmploymentFormNext}
                />
              )}
            </StyledScrollbar>
          </Grid>
        </Grid>
      </Dialog>
      <DesktopInvitationDialog
        open={betterOnDesktopModal}
        setOpen={setBetterOnDesktopModal}
      />
    </ThemeProvider>
  );
}
