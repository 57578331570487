import { CircularProgress, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import StyledModal from './StyledModal';

type Props = {
  onClick?: MouseEventHandler<HTMLDivElement>;
  onModalClose?: () => void;
  isOpen: boolean;
  onConfirm: () => void;
  onDecline?: () => void;
  title?: string;
  action?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  fullWidth?: boolean;
  contentChildren?: React.ReactNode;
  isLoading?: boolean;
  onConfirmButtonLabel?: string;
  onDeclineButtonLabel?: string;
  isConfirmDisabled?: boolean;
  header?: React.ReactNode;
  actionsJustifyPosition?: 'left' | 'center' | 'right';
};

const ConfirmDialog: FC<Props> = ({
  onModalClose,
  isOpen,
  onClick,
  onConfirm,
  onDecline,
  title,
  action,
  maxWidth = 'sm',
  fullWidth = false,
  contentChildren,
  isLoading = false,
  onConfirmButtonLabel,
  onDeclineButtonLabel,
  isConfirmDisabled,
  header,
  actionsJustifyPosition = 'center'
}) => {
  const { t } = useTranslation('common');

  const onClose = () => {
    if (onDecline) {
      onDecline();
    }
  };

  return (
    <StyledModal
      onClick={onClick}
      isOpen={isOpen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      title={title ? title : 'Confirmación'}
      onClose={onModalClose || onClose}
      disableEscapeKeyDown
      {...(header && {
        header: header
      })}
    >
      <Grid container justifyContent="center" direction="column">
        <Grid item xs={12}>
          {contentChildren ? (
            contentChildren
          ) : (
            <DialogContentText textAlign="left">
              {t('common.confirm.action_confirmation', {
                action: action
              })}
            </DialogContentText>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent={actionsJustifyPosition}
            spacing={1}
            mt={1}
          >
            {onDecline && (
              <Grid item>
                <Button
                  onClick={onDecline}
                  disabled={isLoading}
                  variant="outlined"
                >
                  {onDeclineButtonLabel || t('common.no')}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                onClick={onConfirm}
                disabled={isLoading || isConfirmDisabled}
                variant="contained"
              >
                {isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  onConfirmButtonLabel || t('common.yes')
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledModal>
  );
};

export default ConfirmDialog;
