import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { VARIANTS } from '../../../../contexts/SnackbarContext';
import useSnackbars from '../../../../hooks/useSnackbar';
import api from '../../../../services/api';
import { PROFILE_CACHE_KEYS } from '../Profile.constants';

interface UpdateUserProps {
  userId?: number;
  userObj: User;
  updatedUser: FormData;
}

const updateUserRequest = async (updateUserData: UpdateUserProps) => {
  const { data } = await api.patch(
    `/auth/users/${updateUserData.userId}`,
    updateUserData.updatedUser
  );
  return data;
};

const useUpdateUser = () => {
  const { user_id: id } = jwtDecode<any>(localStorage.getItem('token') || '');
  const queryClient = useQueryClient();
  const { t } = useTranslation('common');
  const { addSnackbar } = useSnackbars();

  return useMutation(
    (updateUserData: UpdateUserProps) => updateUserRequest(updateUserData),
    {
      onMutate: async (updateUserData: UpdateUserProps) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(PROFILE_CACHE_KEYS.selectedProfile);
        const previousSelectedUser: User | undefined = queryClient.getQueryData(
          [PROFILE_CACHE_KEYS.selectedProfile, id]
        );

        const newUser = {
          ...updateUserData.userObj,
          ...previousSelectedUser
        };

        queryClient.setQueryData(
          [PROFILE_CACHE_KEYS.selectedProfile, id],
          newUser
        );
        return newUser;
      },
      onError: (error) => {
        console.debug(error);
        const errorValuesList: string[] = [];
        if (axios.isAxiosError(error) && error.response?.status === 400) {
          Object.values(error.response.data).forEach((errorValue: any) => {
            errorValuesList.push(errorValue.toString());
          });
        }
        addSnackbar(
          VARIANTS.error,
          t('common.alerts.registry_error', {
            action: `${t('common.updated')} ${errorValuesList}`
          })
        );
      },
      onSuccess: (_, varibles) => {
        queryClient.invalidateQueries(PROFILE_CACHE_KEYS.profiles);

        // Check if the user has completed the onboarding process and refetch the profile
        if (varibles.updatedUser.get('has_completed_onboarding')) {
          queryClient.invalidateQueries(PROFILE_CACHE_KEYS.selectedProfile);
        }
        addSnackbar(
          VARIANTS.success,
          t('common.alerts.registry_success', {
            action: t('common.updated')
          })
        );
      }
    }
  );
};

export default useUpdateUser;
