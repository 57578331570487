import { useQuery } from 'react-query';
import api from '../../../../services/api';
import { PROFILE_CACHE_KEYS } from '../Profile.constants';

const getLaborSectorsList = async (): Promise<LaborSector[]> => {
  const { data } = await api.get(`/common/location/labor-sectors`);
  return data;
};

const useLaborSectorsList = (): any => {
  return useQuery<LaborSector[]>(
    [PROFILE_CACHE_KEYS.laborSectors],
    async () => getLaborSectorsList(),
    { staleTime: 0, keepPreviousData: true }
  );
};

export default useLaborSectorsList;
