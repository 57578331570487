import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Box } from '@mui/system';
import { useState } from 'react';

type Props = {
  isDisabled?: boolean;
  bankName?: string;
};
export default function BankIcon({ bankName }: Props, { ...props }) {
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <Box height="1.5rem">
      {!loaded && <AccountBalanceIcon {...props} sx={{ height: '1.5rem' }} />}
      <img
        style={{
          display: !loaded ? 'none' : 'block',
          height: '1.5rem',
          width: '1.5rem'
        }}
        src={`/static/png/banks/${bankName}.png`}
        alt="BankIcon"
        {...props}
        onLoad={() => setLoaded(true)}
      />
    </Box>
  );
}
