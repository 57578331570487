const Logo = (props: any) => {
  return (
    <img
      alt="Factcil"
      src="/static/factcil/factcil_squared_elephant.svg"
      style={{ maxWidth: '100%', maxHeight: 68, alignSelf: 'center' }}
      {...props}
    />
  );
};

export default Logo;
