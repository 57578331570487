import { StepIconProps } from '@mui/material/StepIcon';
import { styled } from '@mui/system';
import React from 'react';

const StyledOnboardingStep = styled('div')<{
  ownerState: { active?: boolean };
}>(({ theme }) => ({
  '& .BlueIcon-activeIcon': {
    backgroundColor: theme.palette.background.default,
    width: '1.25rem',
    height: '0.5rem',
    borderRadius: '1.25rem',
    zIndex: 1
  },
  '& .BlueIcon-BaseIcon': {
    backgroundColor: `${theme.palette.grey[50]}cd`,
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '1.25rem',
    zIndex: 1
  }
}));

export function OnboardingStepperIcon(props: StepIconProps) {
  const { active, className } = props;
  return (
    <StyledOnboardingStep ownerState={{ active }} className={className}>
      <div className={active ? 'BlueIcon-activeIcon' : 'BlueIcon-BaseIcon'} />
    </StyledOnboardingStep>
  );
}
