import CloseIcon from '@mui/icons-material/Close';
import {
  DialogProps,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { FC } from 'react';

type Props = Omit<DialogProps, 'open'> & {
  isOpen: boolean;
  title: string;
  onClose?: () => void;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  fullWidth?: boolean;
  description?: string;
  list?: any[];
  listKey?: string;
  header?: React.ReactNode | null;
};

const StyledModal: FC<Props> = ({
  isOpen,
  title,
  onClose,
  maxWidth = 'sm',
  fullWidth = false,
  description,
  header,
  ...props
}) => {
  return (
    <Dialog
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClose={onClose || undefined}
      sx={{
        [`& .${dialogClasses.root}`]: {
          borderRadius: '8px'
        }
      }}
      {...props}
    >
      <Grid container justifyContent="center" sx={{ pt: 3, pr: 2 }}>
        {header ? (
          <>{header}</>
        ) : (
          <>
            <Grid item xs={12} sx={{ textAlign: 'end' }}>
              <IconButton
                onClick={onClose}
                sx={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} sx={{ pl: 3, pb: 2 }}>
              <Typography variant="h6" align="left" fontWeight="bold">
                {title}
              </Typography>
            </Grid>
          </>
        )}
        <Divider sx={{ width: '100%' }} orientation="horizontal" flexItem />
        {description && (
          <Grid item xs={12} sx={{ pl: 3, pt: 3 }}>
            <DialogContentText align="left">{description}</DialogContentText>
          </Grid>
        )}
        <Grid item xs={12}>
          <DialogContent>{props.children}</DialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default StyledModal;
