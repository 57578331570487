import { Stack, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import ErrorIcon from '../../../Icons/ErrorIcon';

const handleNumbers = (e: React.KeyboardEvent<any>, withDecimals: boolean) => {
  const symbolsAllowed = [
    'Control',
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
    'Shift',
    'Delete'
  ];
  if (symbolsAllowed.includes(e.key)) {
    return;
  }
  const re = withDecimals ? /^\d*\.?\d*$/ : /^[0-9\b]+$/;

  if (!re.test(e.key)) {
    e.preventDefault();
  }
};

export type RHFInputType = Omit<TextFieldProps, 'error'> & {
  name: string;
  control?: Control<any>;
  dataCy?: string;
  error?: any;
  maxLength?: number | null;
  onlyNumbers?: boolean;
  onlyNumbersWithDecimals?: boolean;
  defaultValue?: any;
};

export function RHFInput(textFieldProps: RHFInputType) {
  const {
    name,
    inputProps,
    dataCy,
    error,
    control,
    maxLength,
    variant = 'outlined',
    onlyNumbers = false,
    onlyNumbersWithDecimals = false,
    defaultValue,
    helperText,
    ...rest
  } = textFieldProps;
  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          {...rest}
          error={!!error}
          helperText={
            error ? (
              <Stack direction="row">
                <ErrorIcon style={{ marginRight: 10 }} /> {error.message}{' '}
              </Stack>
            ) : (
              helperText
            )
          }
          inputProps={{ ...inputProps, maxLength: maxLength || null }}
          variant={variant}
          fullWidth
          onKeyDown={(e) => {
            if (onlyNumbers === true) {
              handleNumbers(e, false);
            } else if (onlyNumbersWithDecimals === true) {
              handleNumbers(e, true);
            }
          }}
        />
      )}
      control={control}
      name={name}
      defaultValue={defaultValue}
    />
  );
}
