export const LICENSE_ALLOWED_ROLES = [
  'selfemployee',
  'free_selfemployee',
  'ops'
];

export const POPULATE_USER_REDIRECT_ERRORS = [
  'missing_license_type',
  'unknown_license_type'
];

export const setAuthToken = (token: string) =>
  localStorage.setItem('token', token);

export const getAuthToken = () => localStorage.getItem('token');

export const deleteAuthToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('has_completed_onboarding');
  localStorage.removeItem('has_completed_tour');
  localStorage.removeItem('show_licensing_banner');
  localStorage.removeItem('is_allowed_to_show_billing_document_modal');
  window.location.href = '/login';
};

export const pricingURLFormatter = (formEmail: string | undefined) => {
  if (formEmail) {
    const emailBase64 = btoa(formEmail);
    return `${process.env.REACT_APP_LANDING_SERVER_URL}/pricing?payload=${emailBase64}`;
  }
  return `${process.env.REACT_APP_LANDING_SERVER_URL}/pricing`;
};
