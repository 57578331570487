import jwtDecode from 'jwt-decode';
import { getAuthToken } from './auth';

export const openFileFromUrl = async (url?: string) => {
  if (url) {
    const link = document.createElement('a');
    link.href = url;

    // Set attributes to link
    link.setAttribute('target', '_blank');
    link.click();
  }
};

export const addFileToFormData = (
  formData: FormData,
  file: any,
  key: string
) => {
  if (file) {
    formData.append(key, file);
  }
};

export const getErrorMessage = (error: any, defaultMessage: string, t: any) => {
  let errorMessage = '';
  if (error.response.status === 400) {
    const errorData = error.response.data;
    Object.entries<any>(errorData).forEach(([key, value]) => {
      errorMessage += `${key}: ${value}\n`;
    });
  } else if (error.response.status === 404) {
    errorMessage = t('error.notFound');
  } else if (error.response.status === 403) {
    errorMessage = t('error.forbidden');
  }
  return errorMessage || defaultMessage;
};

export const getDecodeToken = () => {
  const token = getAuthToken();
  if (token) {
    return jwtDecode<any>(token);
  }
  return null;
};

export const isTokenValid = () => {
  const decodeToken = getDecodeToken();
  if (!decodeToken) return false;
  if (decodeToken.exp > Date.now()) return false;
  return true;
};

export const checkRoles = (allowedRoles?: Role[]) => {
  const decodeToken = getDecodeToken();
  const groups = decodeToken?.groups?.map((group: any) => group);
  return allowedRoles?.some((role) => groups?.includes(role.toLowerCase()));
};

export const calculateServicesPrice = (addedServices: Object[]) => {
  return Object.values(addedServices as Service[])
    .reduce(
      (sum, service) =>
        sum + (Number(service.unit_price) * Number(service.quantity || 1) || 0),
      0
    )
    .toString();
};

export const checkUserLicense = (license_name: string) => {
  const decodeToken = getDecodeToken();
  const userCurrentLicense = decodeToken?.active_license?.license_type.name;
  return userCurrentLicense === license_name;
};
