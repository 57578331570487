import React, { FC } from 'react';
import { useTracking } from 'react-tracking';
import { useUser } from '../../contexts/UserContext';
import { createUserTracking } from './api/createUserTracking';

const UserTracking: FC = ({ children }) => {
  const user = useUser();
  const userTrackingCallback = async (data: UserTrackingBase) => {
    try {
      if (data && user) {
        const formData = new FormData();
        Object.entries<any>(data).forEach(([key, value]) => {
          formData.append(key, value);
        });
        createUserTracking(formData);
      }
      return true;
    } catch {
      return false;
    }
  };

  const { Track } = useTracking(
    {},
    {
      dispatch: (data: UserTrackingBase) => userTrackingCallback(data)
    }
  );

  return <Track>{children}</Track>;
};

export default UserTracking;
