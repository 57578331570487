import { ReactComponent as ErrorIcon } from '@../../../public/static/svg/icons/snackbar/error.svg';
import { ReactComponent as SuccessIcon } from '@../../../public/static/svg/icons/snackbar/success.svg';
import { Alert, Snackbar, SvgIcon } from '@mui/material';
import { createContext, FC, ReactNode, useCallback, useState } from 'react';

export enum VARIANTS {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info'
}

const fontColors: any = {
  success: '#0DBC7D',
  error: '#E65057',
  warning: '#ED6C02',
  info: '#0288D1'
};

const paperBorder: any = {
  success: '#E3F9F1',
  error: '#FDEAEB',
  warning: '#E3F9F1',
  info: '#E3F9F1'
};

type ProviderProps = {
  children: ReactNode;
};

interface SnackbarContextType {
  addSnackbar: (variant: string, message: string | React.ReactNode) => void;
}

export const SnackbarContext = createContext<SnackbarContextType>(
  {} as SnackbarContextType
);

export const SnackbarProvider: FC<ProviderProps> = ({ children }) => {
  const [alert, setAlert] = useState<any>(undefined);
  const [open, setOpen] = useState<boolean>(false);

  const addSnackbar = useCallback(
    (variant: string, message: string | React.ReactNode) => {
      setAlert({
        severity: variant,
        message
      });
      setOpen(true);
    },
    []
  );

  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setAlert(undefined);
  };

  const getAlertFontColor = (severity: VARIANTS) => {
    return fontColors[severity];
  };

  const getPaperBorderColor = (severity: VARIANTS) => {
    return paperBorder[severity];
  };

  return (
    <SnackbarContext.Provider value={{ addSnackbar }}>
      {children}
      {open && alert && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          key={new Date().getTime()}
          open={open}
          onClose={handleClose}
          autoHideDuration={5000}
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: (theme) => theme.palette.background.default,
              border: `1px solid ${getPaperBorderColor(alert.severity)}`,
              borderRadius: '8px',
              boxShadow: '0px 0px 40px rgba(0, 36, 50, 0.05)'
            }
          }}
        >
          <Alert
            onClose={handleClose}
            severity={alert.severity}
            sx={{
              ...{ color: getAlertFontColor(alert.severity) },
              fontFamily:
                '"Lexend", -apple-system, BlinkMacSystemFont, "Helvetica", "Arial", sans-serif',
              fontSize: '16px'
            }}
            iconMapping={{
              success: (
                <SvgIcon>
                  <SuccessIcon />
                </SvgIcon>
              ),
              error: (
                <SvgIcon>
                  <ErrorIcon />
                </SvgIcon>
              )
            }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
};
