import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Autocomplete as RealAutocomplete,
  AutocompleteProps,
  Stack,
  TextField,
  TextFieldProps
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '../../Icons/ErrorIcon';

export type AutocompleteType = Omit<TextFieldProps, 'error'> & {
  error?: any;
  textFieldProps?: Omit<TextFieldProps, 'error'>;
  autocompleteProps: Partial<AutocompleteProps<any, any, any, any>>;
};

export function Autocomplete(props: AutocompleteType) {
  const { t } = useTranslation('common');
  const {
    textFieldProps,
    autocompleteProps,
    error,
    variant = 'outlined'
  } = props;
  const {
    options = [],
    popupIcon = <KeyboardArrowDown sx={{ color: '#002432' }} />,
    fullWidth = true
  } = autocompleteProps;
  return (
    <RealAutocomplete
      {...autocompleteProps}
      popupIcon={popupIcon}
      options={options}
      fullWidth={fullWidth}
      noOptionsText={t('common.no_options')}
      renderInput={(params) => (
        <TextField
          error={!!error}
          helperText={
            error ? (
              <Stack direction="row">
                <ErrorIcon style={{ marginRight: 10 }} /> {error.message}{' '}
              </Stack>
            ) : null
          }
          variant={variant}
          {...params}
          {...textFieldProps}
        />
      )}
    />
  );
}
