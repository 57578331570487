import { useQuery } from 'react-query';
import api from '../services/api';
import { COMMON_CACHE_KEYS } from './Common.constants';

const getStatesList = async (
  countryId: number | undefined
): Promise<State[] | undefined> => {
  if (!countryId) {
    return undefined;
  }
  const { data } = await api.post(`/common/location/${countryId}/states`);
  return data;
};

const useStatesList = (countryId: number | undefined): any => {
  return useQuery<State[] | undefined>(
    [COMMON_CACHE_KEYS.states],
    async () => getStatesList(countryId),
    {
      staleTime: 0,
      keepPreviousData: true
    }
  );
};

export default useStatesList;
