import { TextField, TextFieldProps } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

type RHFNumberFormatProps = Omit<
  NumberFormatProps<TextFieldProps>,
  'error' | 'onchange'
> & {
  name: string;
  control: Control<any>;
  error?: any;
};

export const RHFNumberFormat = (rhfNumberFormatType: RHFNumberFormatProps) => {
  const {
    name,
    control,
    error,
    variant,
    type = 'text',
    onChange,
    ...rest
  } = rhfNumberFormatType;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <NumberFormat
          {...field}
          {...rest}
          onChange={
            onChange
              ? onChange
              : (e: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(e.target.value.replace(/\D+/g, ''))
          }
          customInput={TextField}
          variant={variant || 'outlined'}
          error={!!error}
          helperText={error ? error.message : null}
          fullWidth
          type={type}
        />
      )}
    />
  );
};
