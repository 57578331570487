import { createContext, useContext, useState } from 'react';

type NavBarContextInterface = {
  isNavOpen: boolean;
  handleChangeOpen: () => void;
};
const NavBarContext = createContext<NavBarContextInterface>(
  {} as NavBarContextInterface
);

export const NavBarProvider = ({ children }: any): any => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleChangeOpen = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <NavBarContext.Provider value={{ isNavOpen, handleChangeOpen }}>
      {children}
    </NavBarContext.Provider>
  );
};

export const useNavbarContext = () => useContext(NavBarContext);
