import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { acceptedFileExtensionsListGenerator } from '../../utils/generators';
import UploadedFilePreview from './UploadedFilePreview';

type Props = {
  file: File | null;
  handleFile: (files: File | null) => void;
  handleError?: () => void;
  acceptedExtensions?: FileExtensions[];
  placeholder: string;
  onDropFuncCall?: () => void;
  onViewFile?: (file: File) => void;
};

export default function FileUploadInput({
  file,
  handleFile,
  handleError,
  acceptedExtensions,
  placeholder,
  onDropFuncCall,
  onViewFile
}: Props) {
  const { t } = useTranslation('common');

  const handleDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0 && handleError) handleError();
      if (acceptedFiles.length === 1) {
        handleFile(acceptedFiles[0]);
      }
      if (onDropFuncCall) onDropFuncCall();
    },
    [handleFile, handleError, onDropFuncCall]
  );
  const handleDeleteFile = useCallback(() => handleFile(null), [handleFile]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept:
      acceptedExtensions &&
      acceptedFileExtensionsListGenerator(acceptedExtensions)
  });

  return file ? (
    <UploadedFilePreview
      file={file}
      onViewFile={onViewFile}
      handleDeleteFile={handleDeleteFile}
    />
  ) : (
    <Grid
      sx={[
        {
          border: `0.063rem dashed`,
          borderColor: 'grey.600',
          borderRadius: '0.5rem',
          height: '3.75rem',
          boxShadow: 'none',

          '&:hover': {
            backgroundColor: 'action.hover'
          }
        },
        !file && {
          cursor: 'pointer'
        },
        isDragActive && {
          backgroundColor: 'action.hover'
        }
      ]}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Box
        display="flex"
        height="100%"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        px="0.25rem"
      >
        {isDragActive ? (
          <Typography variant="body2">
            {t('common.form.drop_file_invitation')}
          </Typography>
        ) : (
          <Typography variant="body2">
            <Typography
              component="span"
              variant="body2"
              fontWeight="600"
              color="action.active"
            >
              {`${t('common.form.select_short')} `}
            </Typography>
            {placeholder}
          </Typography>
        )}
      </Box>
    </Grid>
  );
}
