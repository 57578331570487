import { t } from 'i18next';
import jwtDecode from 'jwt-decode';
import api from '../services/api';

export const stepGeneratorOnID = (stepList: string[]) =>
  stepList.map((step) => ({
    selector: `#${step}`,
    content: t(`common:tour.${step}`) || ''
  }));

export const onTourFinish = (flag: string) => {
  const { user_id: id } = jwtDecode<any>(localStorage.getItem('token') || '');
  const flagChange = Object.assign({}, { [flag]: true });
  localStorage.setItem(flag, 'true');
  api.patch(`/auth/users/${id}`, flagChange);
};
