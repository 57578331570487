import { useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import { checkRoles, getDecodeToken } from '../utils/functions';

/**
 * Hook to set the user context when the user is already logged in
 */
export const useUserContext = () => {
  const user = useUser();
  useEffect(() => {
    const decodedToken = getDecodeToken();
    if (decodedToken) {
      const currentUser: AuthUser = {
        user_id: decodedToken.user_id,
        first_name: decodedToken.first_name,
        last_name: decodedToken.last_name,
        email: decodedToken.email,
        groups: decodedToken.groups?.map((group: any) => group),
        self_referral_code: decodedToken.self_referral_code,
        has_completed_onboarding: decodedToken.has_completed_onboarding,
        active_license: decodedToken.active_license,
        is_assistance: decodedToken.is_assistance,
        operation_email: decodedToken.operation_email
      };
      if ('is_allowed_to_show_billing_document_modal' in decodedToken) {
        localStorage.setItem(
          'is_allowed_to_show_billing_document_modal',
          decodedToken.is_allowed_to_show_billing_document_modal
        );
      }
      if ('active_license' in decodedToken) {
        const licensingRemainingDays =
          decodedToken.active_license?.remaining_days;
        let showLicensingBanner = !checkRoles(['OPERATIONS', 'OPS']);
        if (
          licensingRemainingDays === undefined ||
          (licensingRemainingDays &&
            licensingRemainingDays >
              parseInt(process.env.REACT_APP_REFERRAL_DISCOUNT || '15', 10))
        ) {
          showLicensingBanner = false;
        }

        localStorage.setItem(
          'show_licensing_banner',
          showLicensingBanner.toString()
        );
      }
      user?.setCurrentUser(currentUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
