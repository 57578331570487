import { FC, Fragment, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import GuestAuthGuard from './components/GuestAuthGuard';
import { default as GuestGuard } from './components/GuestGuard';
import Menu from './components/Menu/Menu';
import RolesGuard from './components/RolesGuard';
import { useUserContext } from './hooks/useUserContext';

type RoutesType = {
  exact?: boolean;
  path?: string;
  guard?: FC;
  guardParams?: any;
  layout?: FC;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  component?: any;
  routes?: RoutesType[];
};

const routesConfig: RoutesType[] = [
  {
    exact: true,
    guard: AuthGuard,
    path: '/',
    component: () => <Navigate to="/dashboard" />
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/r/:referralCode',

    component: lazy(() => import('./views/pages/Public/Auth/ReferralRedirect'))
  },
  {
    path: '/dashboard/*',
    guard: AuthGuard,
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Dashboard/index'))
      }
    ]
  },
  {
    path: '/onboarding/*',
    guard: AuthGuard,
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Dashboard/index'))
      }
    ]
  },
  {
    path: '/companies/*',
    guard: RolesGuard,
    guardParams: [
      'SELFEMPLOYEE',
      'OPS',
      'FREE_SELFEMPLOYEE',
      'ONDEMAND',
      'OPERATIONS'
    ],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/list',
        component: lazy(() => import('./views/pages/Companies/List'))
      },
      ...['', '/:returnTo/:idCode'].map((r: string) => {
        // returnTo any of p: payment_request | b: billing
        return {
          exact: true,
          path: `/create${r}`,
          component: lazy(() => import('./views/pages/Companies/CompaniesForm'))
        };
      }),

      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Companies/CompaniesForm'))
      }
    ]
  },
  {
    path: '/bill/*',
    guard: RolesGuard,
    guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE', 'ONDEMAND'],
    layout: Menu,
    routes: [
      {
        exact: true,
        guard: RolesGuard,
        path: '/list',
        guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE', 'ONDEMAND'],
        component: lazy(() => import('./views/pages/Bills/index'))
      },
      ...['', '/:invoice', '/draft/:draftId'].map((r: string) => {
        return {
          guard: RolesGuard,
          exact: true,
          path: `/create${r}`,
          guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE', 'ONDEMAND'],
          component: lazy(() => import('./views/pages/Bills/BillForm/form'))
        };
      }),
      {
        guard: RolesGuard,
        exact: true,
        path: '/:id/edit',
        guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE'],
        component: lazy(() => import('./views/pages/Bills/BillForm/form'))
      },
      {
        guard: RolesGuard,
        exact: true,
        path: '/:id/edit/:invoice',
        guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE'],
        component: lazy(() => import('./views/pages/Bills/BillForm/form'))
      }
    ]
  },
  {
    path: '/factcil-portfolio',
    guard: RolesGuard,
    guardParams: ['OPERATIONS'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(
          () => import('./views/pages/Metrics/Portfolio/PortfoliosFactcil')
        )
      }
    ]
  },
  {
    path: '/reports',
    guard: RolesGuard,
    guardParams: ['OPERATIONS'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(
          () => import('./views/pages/PaymentRequests/Reports/index')
        )
      }
    ]
  },
  {
    path: '/provider-portfolio',
    guard: RolesGuard,
    guardParams: ['OPERATIONS'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Metrics/Portfolio/index'))
      }
    ]
  },
  {
    path: '/payment-request/*',
    guard: RolesGuard,
    guardParams: [
      'SELFEMPLOYEE',
      'OPERATIONS',
      'OPS',
      'FREE_SELFEMPLOYEE',
      'FINANCIAL',
      'ONDEMAND'
    ],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/list',
        component: lazy(() => import('./views/pages/PaymentRequests/List'))
      },

      ...['', '/draft/:draftId'].map((r: string) => {
        return {
          guard: RolesGuard,
          guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE', 'ONDEMAND'],
          exact: true,
          path: `/create${r}`,
          component: lazy(() => import('./views/pages/PaymentRequests/form'))
        };
      }),
      {
        guard: RolesGuard,
        guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE', 'ONDEMAND'],
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/PaymentRequests/form'))
      },
      {
        guard: RolesGuard,
        guardParams: ['OPERATIONS'],
        exact: true,
        path: '/:id/create-quote',
        component: lazy(
          () => import('./views/pages/PaymentRequests/PaymentQuoteForm')
        )
      }
    ]
  },
  {
    path: '/rejected-payment-request/*',
    guard: RolesGuard,
    guardParams: ['OPERATIONS'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/list',
        component: lazy(
          () => import('./views/pages/PaymentRequests/RejectedList')
        )
      }
    ]
  },
  {
    path: '/pending-disbursement-payment-request/*',
    guard: RolesGuard,
    guardParams: ['OPERATIONS'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/list',
        component: lazy(
          () => import('./views/pages/PaymentRequests/PendingDisbursementList')
        )
      }
    ]
  },
  {
    path: '/disbursed-payment-request/*',
    guard: RolesGuard,
    guardParams: ['OPERATIONS', 'ACCOUNTING_OFFICER'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/list',
        component: lazy(
          () => import('./views/pages/PaymentRequests/DisbursedList')
        )
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(
          () => import('./views/pages/PaymentRequests/DisbursedForm')
        )
      },
      {
        guard: RolesGuard,
        guardParams: ['OPERATIONS'],
        exact: true,
        path: '/:id/edit-quote/:quoteId',
        component: lazy(
          () => import('./views/pages/PaymentRequests/PaymentQuoteForm')
        )
      }
    ]
  },
  {
    path: '/services/*',
    guard: RolesGuard,
    guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE', 'ONDEMAND'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/list',
        component: lazy(() => import('./views/pages/Services/List'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Services/ServiceForm'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Services/ServiceForm'))
      }
    ]
  },
  {
    path: '/licensing/*',
    guard: RolesGuard,
    guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE', 'ONDEMAND'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/license-types/list',
        component: lazy(
          () => import('./views/pages/Licensing/LicenseTypes/List')
        )
      },
      {
        exact: true,
        path: '/license-types/create',
        component: lazy(
          () => import('./views/pages/Licensing/LicenseTypes/LicenseTypeForm')
        )
      },
      {
        exact: true,
        path: '/license-types/:id/edit',
        component: lazy(
          () => import('./views/pages/Licensing/LicenseTypes/LicenseTypeForm')
        )
      },
      {
        exact: true,
        path: '/user-licenses/list',
        component: lazy(
          () => import('./views/pages/Licensing/UserLicenses/List')
        )
      }
    ]
  },
  {
    path: '/payrolls/*',
    guard: RolesGuard,
    guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE', 'ONDEMAND'],
    layout: Menu,
    routes: [
      ...['', '/:returnTo/:idCode'].map((r: string) => {
        return {
          exact: true,
          path: `/list${r}`,
          component: lazy(() => import('./views/pages/Payrolls/PayrollList'))
        };
      }),
      {
        exact: true,
        path: '/create',
        component: lazy(
          () => import('./views/pages/Payrolls/PayrollForm/PayrollForm')
        )
      },
      {
        exact: true,
        path: '/arus-register',
        component: lazy(
          () => import('./views/pages/Payrolls/ArusRegisterStepper')
        )
      }
    ]
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/pages/Public/Auth/Login/index'))
  },
  {
    exact: true,
    guard: GuestAuthGuard,
    path: '/logout',
    component: lazy(() => import('./views/pages/Public/Auth/Logout/index'))
  },

  {
    path: '/signup/*',
    guard: GuestGuard,
    layout: undefined,
    guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE', 'ONDEMAND'],
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Public/Auth/SignUp/index'))
      },
      {
        exact: true,
        path: '/pricing-form',
        component: lazy(
          () => import('./views/pages/Public/Auth/SignUp/PricingForm/index')
        )
      },
      {
        exact: true,
        path: '/pricing-plans',
        component: lazy(
          () => import('./views/pages/Public/Auth/SignUp/LicenseSelector/index')
        )
      },
      {
        exact: true,
        path: '/pricing-table',
        component: lazy(
          () => import('./views/pages/Public/Auth/SignUp/LicenseTable/index')
        )
      }
    ]
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot-password',
    component: lazy(
      () => import('./views/pages/Public/Auth/ForgotPassword/index')
    )
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset/:token/:uidb64',
    component: lazy(
      () => import('./views/pages/Public/Auth/ResetPassword/index')
    )
  },
  {
    path: '/license/*',
    guard: GuestAuthGuard,
    routes: [
      {
        exact: true,
        path: '',
        component: lazy(() => import('./views/pages/Public/Licensing/index'))
      },
      {
        exact: true,
        path: '/epayco-transaction-response/:refPayco',
        component: lazy(
          () => import('./views/pages/Public/Licensing/EpaycoCallback/index')
        )
      },
      {
        path: '/:licensingId',
        component: lazy(
          () => import('./views/pages/Public/Licensing/RegisterSteps/index')
        )
      },
      {
        exact: true,
        path: '/:licensingId/renew/pay',
        component: lazy(
          () => import('./views/pages/Public/RenewLicensing/index')
        )
      }
    ]
  },
  {
    path: '/incomes/*',
    guard: RolesGuard,
    guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE', 'ONDEMAND'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/list',
        component: lazy(() => import('./views/pages/Incomes/List'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Incomes/IncomeForm'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Incomes/IncomeForm'))
      }
    ]
  },
  {
    path: 'contact-us',
    guard: AuthGuard,
    layout: Menu,
    routes: [
      {
        component: lazy(() => import('./views/pages/HelpView/ContactView'))
      }
    ]
  },
  {
    path: '/expenses/*',
    guard: RolesGuard,
    guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE', 'ONDEMAND'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/list',
        component: lazy(() => import('./views/pages/Expenses/List'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Expenses/ExpenseForm'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Expenses/ExpenseForm'))
      }
    ]
  },
  {
    path: '/quotes/*',
    guard: RolesGuard,
    guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/list',
        component: lazy(() => import('./views/pages/Quotes/List'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Quotes/QuoteForm'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Quotes/QuoteForm'))
      }
    ]
  },
  {
    path: '/projects/*',
    guard: RolesGuard,
    guardParams: ['ALLY', 'OPERATIONS', 'FINANCIAL'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/list',
        component: lazy(() => import('./views/pages/Projects/List/index'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(
          () => import('./views/pages/Projects/ProjectForm/index')
        )
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(
          () => import('./views/pages/Projects/ProjectForm/index')
        )
      },
      {
        exact: true,
        path: '/:projectId',
        component: lazy(
          () => import('./views/pages/Projects/ProjectDetail/index')
        )
      }
    ]
  },
  {
    path: '/rejected-projects/*',
    guard: RolesGuard,
    guardParams: ['ALLY', 'OPERATIONS', 'FINANCIAL'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/list',
        component: lazy(() => import('./views/pages/Projects/List/index'))
      }
    ]
  },
  {
    path: '/assistance-rejected-list/*',
    guard: RolesGuard,
    guardParams: ['OPERATIONS'],
    layout: Menu,
    routes: [
      {
        exact: true,
        guard: RolesGuard,
        guardParams: ['OPERATIONS'],
        path: '/',
        component: lazy(
          () => import('./views/pages/Assistances/List/ListRejected')
        )
      }
    ]
  },
  {
    path: '/assisted-provider-list/*',
    guard: RolesGuard,
    guardParams: ['OPERATIONS'],
    layout: Menu,
    routes: [
      {
        exact: true,
        guard: RolesGuard,
        guardParams: ['OPERATIONS'],
        path: '/',
        component: lazy(
          () => import('./views/pages/Assistances/AssistedUsers/List')
        )
      }
    ]
  },
  {
    path: '/assistances/*',
    guard: RolesGuard,
    guardParams: [
      'SELFEMPLOYEE',
      'OPERATIONS',
      'FREE_SELFEMPLOYEE',
      'ONDEMAND'
    ],
    layout: Menu,
    routes: [
      {
        exact: true,
        guard: RolesGuard,
        guardParams: ['OPERATIONS'],
        path: '/assisted-provider-list',
        component: lazy(
          () => import('./views/pages/Assistances/AssistedUsers/List')
        )
      },
      {
        exact: true,
        path: '/list',
        component: lazy(() => import('./views/pages/Assistances/List'))
      },
      {
        guard: RolesGuard,
        guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE', 'ONDEMAND'],
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Assistances/form'))
      },
      {
        exact: true,
        path: '/:assistanceId',
        component: lazy(
          () => import('./views/pages/Assistances/AssistancesDetail')
        )
      },
      {
        guard: RolesGuard,
        guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE', 'ONDEMAND'],
        exact: true,
        path: '/payment',
        component: lazy(() => import('./views/pages/Assistances/Payment/index'))
      },
      {
        guard: RolesGuard,
        guardParams: ['OPERATIONS'],
        exact: true,
        path: '/:id/create-quote',
        component: lazy(
          () => import('./views/pages/PaymentRequests/PaymentQuoteForm')
        )
      },
      {
        guard: RolesGuard,
        guardParams: ['OPERATIONS'],
        exact: true,
        path: '/:id/edit-quote/:quoteId',
        component: lazy(
          () => import('./views/pages/PaymentRequests/PaymentQuoteForm')
        )
      }
    ]
  },
  {
    path: '/referral-codes/*',
    guard: RolesGuard,
    guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE', 'ONDEMAND'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/list',
        component: lazy(
          () => import('./views/pages/ReferralCodes/ReferralCodes')
        )
      }
    ]
  },
  {
    path: '/profile/*',
    guard: RolesGuard,
    guardParams: ['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE', 'ONDEMAND'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/:tab',
        component: lazy(() => import('./views/pages/Profile/index'))
      }
    ]
  },
  {
    path: '/providers/*',
    guard: RolesGuard,
    guardParams: ['OPERATIONS', 'ACCOUNTING_OFFICER'],
    layout: Menu,
    routes: [
      {
        exact: true,
        path: '/list',
        component: lazy(() => import('./views/pages/Providers/List'))
      }
    ]
  },
  {
    path: '/p/help/*',
    guard: GuestGuard,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Public/Help'))
      }
    ]
  },
  {
    exact: true,
    path: '/p/confirmation_email/:token/:uidb64',
    component: lazy(
      () => import('./views/pages/Public/Auth/ConfirmationEmailView/index')
    )
  },
  {
    exact: true,
    guard: GuestAuthGuard,
    path: '/p/billing-payroll/:uidb64',
    component: lazy(() => import('./views/pages/Public/Payroll/Detail'))
  },
  {
    exact: true,
    path: '/p/privacy-policy',
    component: lazy(
      () => import('./views/pages/Public/terms/PrivacyPolicyView')
    )
  },
  {
    exact: true,
    path: '/p/terms-of-service',
    component: lazy(() => import('./views/pages/Public/terms/ToSView'))
  },
  {
    path: '*',
    routes: [
      {
        component: () => <Navigate to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes: RoutesType[]) =>
  routes ? (
    <Suspense fallback={<></>}>
      <Routes>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const GuardParams = {
            ...(route.guardParams && { allowedRoles: route.guardParams })
          };
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <Route
              key={i}
              path={route.path ?? ''}
              element={
                <Guard {...GuardParams}>
                  <Layout>
                    {route.routes ? renderRoutes(route.routes) : <Component />}
                  </Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  ) : null;

const AppRoutes = () => {
  useUserContext();
  return renderRoutes(routesConfig);
};

export default AppRoutes;
