import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { checkRoles } from '../utils/functions';

type Props = { allowedRoles?: Role[] };
const RolesGuard: FC<Props> = ({ allowedRoles, children }) => {
  const isAllowed = checkRoles(allowedRoles);
  return isAllowed ? <>{children}</> : <Navigate to="/login" />;
};

export default RolesGuard;
