import { useQuery } from 'react-query';
import api from '../services/api';
import { COMMON_CACHE_KEYS } from './Common.constants';

const getCitiesList = async (
  stateId: number | undefined
): Promise<City[] | undefined> => {
  if (!stateId) {
    return undefined;
  }
  const { data } = await api.post(`/common/location/${stateId}/cities`);
  return data;
};

const useCitiesList = (stateId: number | undefined): any => {
  return useQuery<City[] | undefined>(
    [COMMON_CACHE_KEYS.cities],
    async () => getCitiesList(stateId),
    { staleTime: 0, keepPreviousData: true }
  );
};

export default useCitiesList;
