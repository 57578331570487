import {
  Autocomplete,
  Box,
  createFilterOptions,
  Divider,
  InputAdornment,
  Popper,
  Stack,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Country } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import {
  getCountries,
  getCountryCallingCode
} from 'react-phone-number-input/input';
import es from 'react-phone-number-input/locale/es.json';
import { RHFNumberFormat } from './RHF/RHFNumberFormat';

type CountryOptions = {
  code: string;
  countryName: string;
  countryPrefix: Country;
};

type CountryCodePhoneType = {
  control: Control<any, object>;
  watchCountryCode: CountryOptions | null;
  errors: any;
  disabled?: boolean;
  phoneLabel?: string;
};

const CountryCodePhone: FC<CountryCodePhoneType> = ({
  control,
  watchCountryCode,
  errors,
  disabled,
  phoneLabel
}) => {
  const { t } = useTranslation('common');

  const countryCodeOptions = () => {
    return getCountries().map((country) => {
      const countryOption: CountryOptions = {
        code: getCountryCallingCode(country),
        countryName: es[country],
        countryPrefix: country
      };
      return countryOption;
    });
  };

  const AutocompletePopper = function (props: any) {
    return (
      <Popper
        {...props}
        style={{ maxWidth: '15rem' }}
        placement="bottom-start"
      />
    );
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: CountryOptions) => option.countryName
  });

  return (
    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1} width={1}>
      <Box gridColumn="span 5">
        <Controller
          control={control}
          name="country_code"
          render={({ field }) => (
            <Autocomplete
              disabled={disabled}
              {...field}
              PopperComponent={AutocompletePopper}
              disableClearable
              options={countryCodeOptions()}
              value={field.value}
              isOptionEqualToValue={(
                option: CountryOptions,
                value: CountryOptions
              ) => option?.code === value?.code}
              getOptionLabel={(option: CountryOptions) => `+${option.code}`}
              filterOptions={filterOptions}
              onChange={(_, data) => {
                field.onChange(data);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    disabled={disabled}
                    label={t('profile.form.country')}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        paddingLeft: 1,
                        paddingRight: 0
                      },
                      startAdornment: (
                        <>
                          {watchCountryCode && (
                            <InputAdornment position="start">
                              <Stack pl={1}>
                                <SvgIcon>
                                  {flags[watchCountryCode.countryPrefix]?.({
                                    title: es[watchCountryCode.countryPrefix]
                                  })}
                                </SvgIcon>
                              </Stack>
                            </InputAdornment>
                          )}
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                  />
                );
              }}
              noOptionsText={t('common.form.select_not_options')}
              renderOption={(props, option: CountryOptions) => (
                <React.Fragment key={option?.countryPrefix}>
                  <li {...props} style={{ background: 'white' }}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <SvgIcon>
                        {flags[option.countryPrefix]?.({
                          title: es[option.countryPrefix]
                        })}
                      </SvgIcon>
                      <Typography>{es[option.countryPrefix]}</Typography>
                      <Typography>
                        {`+${getCountryCallingCode(option.countryPrefix)}`}
                      </Typography>
                    </Stack>
                  </li>
                  <Divider />
                </React.Fragment>
              )}
            />
          )}
        />
      </Box>
      <Box gridColumn="span 7">
        <RHFNumberFormat
          id="phone"
          control={control}
          name="phone"
          error={errors.phone}
          required
          disabled={disabled}
          label={phoneLabel || t('profile.form.phone')}
          autoComplete="phone"
          decimalScale={0}
          allowNegative={false}
        />
      </Box>
    </Box>
  );
};

export default CountryCodePhone;
