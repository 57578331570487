import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Theme } from '@mui/system';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/Buttons/ActionButton';
import RHFAutocomplete from '../../../../components/Forms/inputs/RHF/RHFAutocomplete';
import { RHFInput } from '../../../../components/Forms/inputs/RHF/RHFInput';
import FileUploadInput from '../../../../components/Inputs/FileUploadInput';
import useLaborSectorsList from '../../Profile/hooks/useLaborSectorsList';
import useUpdateUser from '../../Profile/hooks/useUpdateUser';
import useUserDetail from '../../Profile/hooks/useUserDetail';
import { employmentSchema } from './onboardingSchemas';
import { FormCard } from './Stylings';

type Props = {
  onNext: () => void;
  onOmitNext: () => void;
};

export default function EmploymentFormStep({ onNext, onOmitNext }: Props) {
  const { t } = useTranslation('common');
  const atLeastSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const {
    mutate,
    isLoading: isProfileUpdateLoading,
    isSuccess: isProfileUpdateSuccess
  } = useUpdateUser();

  const { data: selectedUser, isLoading: profileLoading } = useUserDetail();

  const { data: laborSectors, isLoading: laborSectorsLoading } =
    useLaborSectorsList();

  const defaultValues = {
    labor_sector: selectedUser?.labor_sector || null,
    work_area: selectedUser?.work_area || ''
  };

  const [RUT, setRUT] = useState<any>(null);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(employmentSchema)
  });

  const onSubmit = (values: any) => {
    const newValues = {
      ...values,
      labor_sector: values.labor_sector?.id || '',
      has_completed_onboarding: true
    };

    const formData = new FormData();
    Object.entries<any>(newValues).forEach(([key, value]) => {
      formData.append(key, value);
    });
    if (RUT) formData.append('rut_file', RUT);

    mutate({
      userId: selectedUser?.id,
      userObj: newValues,
      updatedUser: formData
    });
  };

  const handleOmitNext = () => {
    const values: any = {
      has_completed_onboarding: true
    };

    const formData = new FormData();
    Object.entries<any>(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    mutate({
      userId: selectedUser?.id,
      userObj: values,
      updatedUser: formData
    });
  };

  useEffect(() => {
    if (isProfileUpdateSuccess && RUT) onNext();
    else if (isProfileUpdateSuccess && !RUT) onOmitNext();
  }, [isProfileUpdateSuccess, RUT, onNext, onOmitNext]);

  useEffect(() => {
    if (selectedUser) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  if (profileLoading || laborSectorsLoading) {
    return <>{t('common.loading')}</>;
  }

  if (profileLoading) return <>{t('common.form.loading')}</>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction="column"
        justifyContent="center"
        rowGap={3}
        py={2}
        px={{
          xs: 2,
          md: 0
        }}
        borderRadius={{
          xs: '0.75rem',
          md: 0
        }}
        bgcolor={atLeastSm ? 'background.paper' : 'common.white'}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            {t('profile.onboarding.form.your_employment_profile')}
          </Typography>
          <Typography variant="body2" color="grey.700">
            {t('profile.onboarding.form.your_employment_profile_objective')}
          </Typography>
        </Stack>
        <FormCard>
          <Grid
            container
            spacing={3}
            p={{
              xs: 2,
              md: 4
            }}
          >
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom fontWeight="600">
                {t('profile.form.labor_data')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFAutocomplete
                control={control}
                name="labor_sector"
                error={errors.labor_sector}
                textFieldProps={{
                  label: t('profile.form.labor_sector'),
                  required: true,
                  helperText: errors.labor_sector
                    ? errors.labor_sector
                    : t('profile.form.labor_sector_helper')
                }}
                autocompleteProps={{
                  id: 'labor_sector',
                  options: laborSectors,
                  getOptionLabel: (option) => option.name,
                  isOptionEqualToValue: (option, value) =>
                    option.id === value.id || ('id' in value && 'name' in value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFInput
                id="work_area"
                name="work_area"
                label={t('profile.form.work_area')}
                control={control}
                error={errors.work_area}
                helperText={
                  errors.work_area
                    ? errors.work_area.message
                    : t('profile.form.work_area_helper')
                }
                required
                autoComplete="work_area"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom fontWeight="600">
                {t('profile.form.rut_file')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FileUploadInput
                file={RUT}
                handleFile={setRUT}
                acceptedExtensions={['pdf']}
                placeholder={t('common.form.drag_file_invitation', {
                  file: t('profile.form.rut_file')
                })}
              />
            </Grid>
          </Grid>
        </FormCard>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              {isProfileUpdateLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Button
                  variant="text"
                  fullWidth
                  size="large"
                  onClick={handleOmitNext}
                  sx={{
                    color: 'action.active',
                    '&:hover': {
                      textDecoration: 'underline',
                      background: 'none',
                      color: 'action.active'
                    }
                  }}
                >
                  <Typography variant="inherit">
                    {t('common.form.omit')}
                  </Typography>
                </Button>
              )}
            </Grid>
            <Grid item mr={4}>
              <ActionButton
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                rightarrow={1}
                disabled={!isValid || isProfileUpdateLoading || !RUT}
              >
                <Typography variant="inherit">
                  {t('common.form.finish')}
                </Typography>
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
}
