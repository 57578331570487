import { Avatar } from '@mui/material';
import { FC } from 'react';

type Props = {
  avatarName: string;
};

const StringAvatar: FC<Props> = ({ avatarName }) => {
  function stringAvatar(name: string) {
    const splittedName = name.split(' ');
    return {
      sx: {
        bgcolor: 'action.hover',
        color: 'action.active',
        fontSize: '0.875rem',
        fontWeight: '600'
      },
      children:
        splittedName.length === 1
          ? `${splittedName[0][0]}`.toUpperCase()
          : `${splittedName[0][0]}${splittedName[1][0]}`.toUpperCase()
    };
  }

  return <Avatar {...stringAvatar(avatarName)} />;
};

export default StringAvatar;
