import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { I18nextProvider } from 'react-i18next';
import 'react-perfect-scrollbar/dist/css/styles.css';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import commonES from './translations/es/common.json';

// eslint-disable-next-line import/no-named-as-default-member
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'es',
  resources: {
    es: {
      common: commonES
    }
  }
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID ? process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID : ''
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
