import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Country as CountryCode,
  getCountries,
  getCountryCallingCode
} from 'react-phone-number-input';
import es from 'react-phone-number-input/locale/es.json';
import ActionButton from '../../../../components/Buttons/ActionButton';
import CountryCodePhone from '../../../../components/Forms/inputs/CountryCodePhone';
import RHFAutocomplete from '../../../../components/Forms/inputs/RHF/RHFAutocomplete';
import { RHFInput } from '../../../../components/Forms/inputs/RHF/RHFInput';
import { RHFNumberFormat } from '../../../../components/Forms/inputs/RHF/RHFNumberFormat';
import useCitiesList from '../../../../hooks/useCitiesList';
import useCountriesList from '../../../../hooks/useCountriesList';
import useStatesList from '../../../../hooks/useStatesList';
import { useUserContext } from '../../../../hooks/useUserContext';
import { identificationChoices, sexChoices } from '../../../../utils/choices';
import useUpdateUser from '../../Profile/hooks/useUpdateUser';
import useUserDetail from '../../Profile/hooks/useUserDetail';
import { FormCard } from './Stylings';
import { onboardingProfileSchema } from './onboardingSchemas';

type CountryOptions = {
  code: string;
  countryName: string;
  countryPrefix: CountryCode;
};

type Props = {
  onNext: () => void;
};

export default function ProfileFormStep({ onNext }: Props) {
  useUserContext();
  const { t } = useTranslation('common');
  const atLeastSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [stateCountry, setStateCountry] = useState<Country | null>(null);
  const [cityState, setCityState] = useState<State | null>(null);
  const { mutate, isLoading: isSubmitting, isSuccess } = useUpdateUser();
  const { data: selectedUser, isLoading: profileLoading } = useUserDetail();
  const { data: countries, isLoading: countriesLoading } =
    useCountriesList(true);
  const {
    data: states,
    isLoading: statesLoading,
    refetch: refetchStates
  } = useStatesList(stateCountry?.id);
  const {
    data: cities,
    isLoading: citiesLoading,
    refetch: refetchCities
  } = useCitiesList(cityState?.id);

  const countryCodeOptions = (countryCode: any) => {
    let defaultSelectedCountry: CountryOptions = {
      code: '57',
      countryName: '',
      countryPrefix: 'CO'
    };

    const countriesList = getCountries().map((country: CountryCode) => {
      const countryOption: CountryOptions = {
        code: getCountryCallingCode(country),
        countryName: es[country],
        countryPrefix: country
      };
      return countryOption;
    });

    const filteredCountry = countriesList.find(
      (country) => country.code === countryCode
    );

    if (filteredCountry) {
      defaultSelectedCountry = filteredCountry;
    }

    return defaultSelectedCountry;
  };

  const defaultValues = {
    id: selectedUser?.id ?? '',
    firstname: selectedUser?.first_name ?? '',
    lastname: selectedUser?.last_name ?? '',
    identification_type: selectedUser?.identification_type ?? null,
    identification_number: selectedUser?.identification_number ?? '',
    sex: selectedUser?.sex ?? null,
    country: selectedUser?.country ?? null,
    state: selectedUser?.state ?? null,
    city: selectedUser?.city ?? null,
    address: selectedUser?.address ?? '',
    email: selectedUser?.email ?? '',
    phone: selectedUser?.phone ?? '',
    country_code: selectedUser?.phone_country_code
      ? countryCodeOptions(selectedUser.phone_country_code)
      : null,
    labor_sector: selectedUser?.labor_sector ?? null,
    work_area: selectedUser?.work_area ?? ''
  };

  const {
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(onboardingProfileSchema)
  });

  const watchCountryCode = watch('country_code');

  const onSubmit = (values: any) => {
    const newValues = {
      first_name: values.firstname,
      last_name: values.lastname,
      identification_type: values.identification_type,
      identification_number: values.identification_number,
      sex: values.sex,
      address: values.address,
      email: values.email,
      phone: values.phone,
      phone_country_code: values.country_code.code,
      work_area: values.work_area,
      city: values?.city?.id || '',
      labor_sector: values?.labor_sector?.id || ''
    };

    // Set user value in a formData
    const formData = new FormData();
    Object.entries<any>(newValues).forEach(([key, value]) => {
      formData.append(key, value);
    });

    mutate({
      userId: selectedUser?.id,
      userObj: {
        ...values,
        city: values.city && values.city.id,
        labor_sector: values.labor_sector && values.labor_sector.id
      },
      updatedUser: formData
    });
  };

  useEffect(() => {
    if (countries) {
      setStateCountry(countries[0]);
    }
  }, [countries]);

  useEffect(() => {
    if (stateCountry) {
      refetchStates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCountry]);

  useEffect(() => {
    if (
      selectedUser &&
      !profileLoading &&
      selectedUser?.country &&
      selectedUser?.state
    ) {
      setStateCountry(selectedUser.country);
      setCityState(selectedUser.state);
    }
  }, [selectedUser, profileLoading]);

  useEffect(() => {
    if (cityState) refetchCities();
  }, [cityState, refetchCities]);

  useEffect(() => {
    if (isSuccess) onNext();
  }, [isSuccess, onNext]);

  useEffect(() => {
    if (selectedUser) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  if (profileLoading || countriesLoading || statesLoading || citiesLoading) {
    return <>{t('common.loading')}</>;
  }

  if (profileLoading) return <>{t('common.form.loading')}</>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction="column"
        justifyContent="center"
        rowGap={3}
        py={2}
        px={{
          xs: 2,
          md: 0
        }}
        bgcolor={atLeastSm ? 'background.paper' : 'common.white'}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            {t('profile.onboarding.form.your_information')}
          </Typography>
          <Typography variant="body2" color="grey.700" align="center">
            {t('profile.onboarding.form.your_information_objective')}
          </Typography>
        </Stack>
        <FormCard>
          <Grid
            container
            spacing={3}
            p={{
              xs: 2,
              md: 4
            }}
          >
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom fontWeight="600">
                {t('profile.form.personal_data')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFInput
                control={control}
                name="firstname"
                error={errors.firstname}
                id="firstname"
                label={t('profile.form.first_name')}
                autoComplete="firstname"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFInput
                control={control}
                name="lastname"
                error={errors.lastname}
                id="lastname"
                label={t('profile.form.last_name')}
                autoComplete="lastname"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFAutocomplete
                control={control}
                name="identification_type"
                error={errors.identification_type}
                textFieldProps={{
                  label: t('profile.form.identification_type'),
                  required: true
                }}
                autocompleteProps={{
                  id: 'identification_type',
                  options: identificationChoices,
                  getOptionLabel: (option) =>
                    t(`profile.form.${option.toLowerCase()}`)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFNumberFormat
                control={control}
                name="identification_number"
                error={errors.identification_number}
                label={t('profile.form.identification_number')}
                autoComplete="identification_number"
                disabled={!!selectedUser?.identification_number}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFAutocomplete
                control={control}
                name="sex"
                error={errors.sex}
                textFieldProps={{
                  label: t('profile.form.sex'),
                  required: true,
                  helperText: errors.sex
                    ? errors.sex.message
                    : t('profile.onboarding.form.as_in_your_document')
                }}
                autocompleteProps={{
                  id: 'sex',
                  options: sexChoices,
                  getOptionLabel: (option) =>
                    option ? t(`profile.form.${option}`) : ''
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom fontWeight="600">
                {t('profile.form.contact_data')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFAutocomplete
                control={control}
                name="state"
                error={errors.state}
                textFieldProps={{
                  label: t('profile.form.state')
                }}
                autocompleteProps={{
                  id: 'state',
                  options: states || [],
                  getOptionLabel: (option) => option.name,
                  onChange: async (_, data) => {
                    setCityState(data);
                    setValue('state', data);
                    setValue('city', null);
                  },
                  isOptionEqualToValue: (option, value) =>
                    option.id === value.id || ('id' in value && 'name' in value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFAutocomplete
                control={control}
                name="city"
                error={errors.city}
                textFieldProps={{
                  label: t('profile.form.city')
                }}
                autocompleteProps={{
                  id: 'city',
                  options: cities || [],
                  getOptionLabel: (option) => option.name,
                  isOptionEqualToValue: (option, value) =>
                    option.id === value.id || ('id' in value && 'name' in value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFInput
                control={control}
                name="address"
                error={errors.address}
                id="address"
                label={t('profile.form.address')}
                autoComplete="address"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CountryCodePhone
                control={control}
                watchCountryCode={watchCountryCode}
                errors={errors}
              />
            </Grid>
          </Grid>
        </FormCard>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item mr={4}>
              <ActionButton
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                rightarrow={1}
                disabled={!isValid || isSubmitting}
              >
                <Typography variant="inherit">
                  {t('common.form.next')}
                </Typography>
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
}
