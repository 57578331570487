import { useQuery } from 'react-query';
import api from '../services/api';
import { COMMON_CACHE_KEYS } from './Common.constants';

const getBanksList = async (): Promise<Bank[]> => {
  const { data } = await api.get(`/common/bank`);
  return data;
};

const useBanksList = (): any => {
  return useQuery<Country[]>(
    [COMMON_CACHE_KEYS.banks],
    async () => getBanksList(),
    { staleTime: 0, keepPreviousData: true }
  );
};

export default useBanksList;
