import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import FileSheetIcon from '../Icons/FileSheetIcon';
import TrashCanIcon from '../Icons/TrashCanIcon';

type Props = {
  file: any;
  onViewFile?: (file: any) => void;
  handleDeleteFile?: () => void;
};

const UploadedFilePreview: FC<Props> = ({
  file,
  onViewFile,
  handleDeleteFile
}) => {
  return (
    <Grid
      sx={[
        {
          border: `0.063rem dashed`,
          borderColor: 'grey.600',
          borderRadius: '0.5rem',
          height: '3.75rem',
          boxShadow: 'none',
          marginBottom: '0.5rem'
        }
      ]}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="100%"
        px={2}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          columnGap={1}
        >
          <FileSheetIcon fileType={file ? file.name.split('.').pop() : 'PDF'} />
          <Typography variant="body2" color="primary" textOverflow="ellipsis">
            {file?.name}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          columnGap={1}
        >
          {onViewFile && file && (
            <Button variant="text" onClick={() => onViewFile(file)}>
              Ver
            </Button>
          )}
          {file && handleDeleteFile && (
            <IconButton onClick={handleDeleteFile}>
              <TrashCanIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Grid>
  );
};

export default UploadedFilePreview;
