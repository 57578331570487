import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/system';
import { TourProvider, useTour } from '@reactour/tour';
import esLocale from 'date-fns/locale/es';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import mdTheme from './components/Theme';
import UserTracking from './components/UserTracking/UserTracking';
import { NavBarProvider } from './contexts/NavBarButtonContext';
import { SnackbarProvider } from './contexts/SnackbarContext';
import { UserProvider } from './contexts/UserContext';
import useScript from './hooks/useScript';
import AppRoutes from './Routes';
import { onTourFinish, stepGeneratorOnID } from './utils/tour';
import OnboardingModal from './views/pages/Global/OnboardingModal';

export const queryClient = new QueryClient();

const stepList = [
  'dashboard_navitem',
  'services_navitem',
  'companies_navitem',
  'bills_navitem',
  'advances_navitem'
];

function App() {
  const steps = stepGeneratorOnID(stepList);
  const { setIsOpen } = useTour();

  const onTourClose = () => {
    localStorage.setItem('has_completed_tour', 'true');
    onTourFinish('has_completed_tour');
    setIsOpen(false);
  };

  // CRM INTEGRATION
  useScript(
    process.env.REACT_APP_CRM_SCRIPT_URL,
    process.env.REACT_APP_ENABLE_CRM === 'true'
  );
  // HOTJAR INTEGRATION
  useScript(
    process.env.REACT_APP_HOTJAR_SCRIPT_URL,
    process.env.REACT_APP_ENABLE_HOTJAR === 'true'
  );

  return (
    <SnackbarProvider>
      <NavBarProvider>
        <BrowserRouter>
          <UserProvider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={esLocale}
            >
              <ThemeProvider theme={mdTheme}>
                <UserTracking>
                  <QueryClientProvider client={queryClient}>
                    <TourProvider steps={steps} beforeClose={onTourClose}>
                      <AppRoutes />
                      <OnboardingModal />
                      <ReactQueryDevtools initialIsOpen={false} />
                    </TourProvider>
                  </QueryClientProvider>
                </UserTracking>
              </ThemeProvider>
            </LocalizationProvider>
          </UserProvider>
        </BrowserRouter>
      </NavBarProvider>
    </SnackbarProvider>
  );
}

export default App;
