import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { VARIANTS } from '../contexts/SnackbarContext';
import { useUser } from '../contexts/UserContext';
import api from '../services/api';
import { deleteAuthToken } from '../utils/auth';
import useSnackbars from './useSnackbar';

const logoutUser = async () => {
  const { data } = await api.post(`/auth/logout`);
  return data;
};

const useLogout = () => {
  const user = useUser();
  const { t } = useTranslation('common');
  const { addSnackbar } = useSnackbars();

  return useMutation(() => logoutUser(), {
    onMutate() {
      deleteAuthToken();
      user?.setCurrentUser(null);
    },
    onError: () => {
      addSnackbar(
        VARIANTS.error,
        t('common.alerts.action_error', {
          action: t('common.validated')
        })
      );
    }
  });
};

export default useLogout;
