import { Card, Grid, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import FactcilGreyElephantIcon from '../../../../components/Icons/FactcilGreyElephantIcon';

export const StyledScrollbar = styled(PerfectScrollbar)({
  maxHeight: '100vh',
  borderRadius: '0.75rem'
});

export const RightMobileDecorator = styled(Grid)(({ theme }) => ({
  height: '5rem',
  width: '5rem',
  borderRadius: '50%',
  border: '1px solid',
  borderColor: theme.palette.action.hover,
  position: 'absolute',
  right: 0,
  transform: 'translate(1rem, 1rem)',
  overflow: 'hidden'
}));

export const StepStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.action.active,
  height: '100%',
  borderRadius: '0.75rem'
}));

export const ElephantIcon = styled(FactcilGreyElephantIcon)({
  height: '2.5rem',
  width: '2.25rem'
});

export const FormCard = styled(Card)(({ theme }) => ({
  boxShadow: `0px 0px 40px ${theme.palette.primary.main}0d`
}));
