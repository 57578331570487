import { useEffect } from 'react';

const useScript = (url: string | undefined, enabled: boolean) => {
    useEffect(() => {
        if (url && enabled) {
            const script = document.createElement('script');

            script.src = url;
            script.async = true;

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            }
        }
    }, [url, enabled]);
};

export default useScript;