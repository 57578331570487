import { useQuery } from 'react-query';
import { useUser } from '../../../../contexts/UserContext';
import api from '../../../../services/api';
import { PROFILE_CACHE_KEYS } from '../Profile.constants';

const getUser = async (
  userId: number | string | undefined
): Promise<User | undefined> => {
  if (!userId) {
    return;
  }
  const { data } = await api.get(`/auth/users/${userId}`);
  return data;
};

const useUserDetail = () => {
  const user = useUser();
  const id = user?.currentUser.user_id;
  return useQuery<User | undefined>(
    [PROFILE_CACHE_KEYS.selectedProfile],
    async () => getUser(id),
    {
      staleTime: Infinity,
      notifyOnChangeProps: ['data', 'error'],
      enabled: !!id
    }
  );
};

export default useUserDetail;
