export const PROFILE_CACHE_KEYS = {
  profiles: 'profiles',
  selectedProfile: 'selectedProfile',
  selectedProfileDocuments: 'selectedProfileDocuments',
  bankOptions: 'bankOptions',
  userBank: 'userBank',
  countries: 'countries',
  states: 'states',
  cities: 'cities',
  laborSectors: 'laborSectors',
  userSignature: 'userSignature',
  userLicenseHistoryList: 'userLicenseHistoryList',
  savedPaymentMethods: 'savedPaymentMethods'
};
