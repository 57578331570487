import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { VARIANTS } from '../../../../../contexts/SnackbarContext';
import { useUser } from '../../../../../contexts/UserContext';
import useSnackbars from '../../../../../hooks/useSnackbar';
import api from '../../../../../services/api';
import { LICENSE_ALLOWED_ROLES, setAuthToken } from '../../../../../utils/auth';

type Props = {
  operations_email: string;
};

const loginAsOperations = async (userData: Props) => {
  const { data } = await api.post(
    `/assistance/login-as-operations-user`,
    userData
  );
  return data;
};

const useLoginAsOperations = () => {
  const user = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { addSnackbar } = useSnackbars();

  const loginAsOperationsResponse = useMutation(loginAsOperations, {
    onError: (error) => {
      let errorMessage = '';
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        errorMessage = `${error.response.data.message}`;
      }

      addSnackbar(
        VARIANTS.error,
        t('assistance.assisted_providers_list.login_error', {
          error: t(
            `assistance.assisted_providers_list.${errorMessage}`,
            errorMessage
          )
        })
      );
    },
    onSuccess: (data) => {
      const decodedToken = jwtDecode<any>(data.access);
      const roleNeedsLicense = LICENSE_ALLOWED_ROLES.some((role: string) =>
        decodedToken.groups.includes(role)
      );
      if (roleNeedsLicense && !decodedToken.active_license) {
        addSnackbar(VARIANTS.error, t('Tu licencia expiró.'));
        return;
      }

      setAuthToken(data.access);
      localStorage.setItem(
        'has_completed_onboarding',
        decodedToken.has_completed_onboarding
      );
      localStorage.setItem(
        'has_completed_tour',
        decodedToken.has_completed_tour
      );
      const currentUser: AuthUser = {
        user_id: decodedToken.user_id,
        first_name: decodedToken.first_name,
        last_name: decodedToken.last_name,
        email: decodedToken.email,
        groups: decodedToken.groups?.map((group: any) => group),
        self_referral_code: decodedToken.self_referral_code,
        has_completed_onboarding: decodedToken.has_completed_onboarding,
        active_license: decodedToken.active_license,
        is_assistance: decodedToken.is_assistance
      };
      user?.setCurrentUser(currentUser);
      window.location.reload();
      navigate('/dashboard');
      if (!decodedToken.has_completed_onboarding) {
        navigate('/onboarding?step=1');
        return;
      }
    }
  });
  return loginAsOperationsResponse;
};

export default useLoginAsOperations;
