import { ReactComponent as assistanceIcon } from '@../../../public/static/svg/icons/menu/assistance.svg';
import { ReactComponent as billIcon } from '@../../../public/static/svg/icons/menu/bill.svg';
import { ReactComponent as clientsIcon } from '@../../../public/static/svg/icons/menu/clients.svg';
import { ReactComponent as dashboardIcon } from '@../../../public/static/svg/icons/menu/dashboard.svg';
import { ReactComponent as expensesIcon } from '@../../../public/static/svg/icons/menu/expenses.svg';
import { ReactComponent as incomeIcon } from '@../../../public/static/svg/icons/menu/income.svg';
import { ReactComponent as paymentRequestIcon } from '@../../../public/static/svg/icons/menu/payment-request.svg';
import { ReactComponent as payrollIcon } from '@../../../public/static/svg/icons/menu/payrolls.svg';
import { ReactComponent as servicesIcon } from '@../../../public/static/svg/icons/menu/services.svg';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import SupportIcon from '@mui/icons-material/Support';
import { t as translate } from 'i18next';
import { TFunction } from 'react-i18next';

export const dashboardItem = (t: TFunction): Item => ({
  icon: dashboardIcon,
  key: 'dashboard',
  text: t('navbar.dashboard'),
  href: '/dashboard'
});

export const factcilPortfolio = (t: TFunction): Item => ({
  icon: paymentRequestIcon,
  key: 'factcil-portfolio',
  text: t('navbar.factcil_portfolios'),
  href: '/factcil-portfolio'
});

export const portfolioToRecover = (t: TFunction): Item => ({
  icon: paymentRequestIcon,
  key: 'provider-portfolio',
  text: t('navbar.provider_portfolios'),
  href: '/provider-portfolio'
});

export const paymentRequestItem = (
  t: TFunction,
  in_process: boolean = false
): Item => ({
  icon: paymentRequestIcon,
  key: 'advances',
  text: in_process ? t('navbar.in_process_advances') : t('navbar.advances'),
  href: '/payment-request/list'
});

export const rejectedPaymentRequestItem = (t: TFunction): Item => ({
  icon: paymentRequestIcon,
  key: 'rejected-advances',
  text: t('navbar.rejected_advances'),
  href: '/rejected-payment-request/list'
});

export const pendingDisbursementPaymentRequestItem = (t: TFunction): Item => ({
  icon: paymentRequestIcon,
  key: 'pending-disbursement-advances',
  text: t('navbar.pending_disbursement_advances'),
  href: '/pending-disbursement-payment-request/list'
});

export const disbursedPaymentRequestItem = (t: TFunction): Item => ({
  icon: paymentRequestIcon,
  key: 'disbursed-advances',
  text: t('navbar.disbursed_advances'),
  href: '/disbursed-payment-request/list'
});

export const serviceItem = (t: TFunction): Item => ({
  icon: servicesIcon,
  key: 'services',
  text: t('navbar.services'),
  href: '/services/list'
});

export const companyItem = (t: TFunction): Item => ({
  icon: clientsIcon,
  key: 'companies',
  text: t('navbar.clients'),
  href: '/companies/list'
});

export const quoteItem = (t: TFunction): Item => ({
  icon: RequestQuoteOutlinedIcon,
  key: 'quotes',
  text: t('navbar.quotes'),
  href: '/quotes/list',
  iconAdjust: '20px'
});

export const projectItem = (t: TFunction): Item => ({
  icon: RequestQuoteOutlinedIcon,
  key: 'projects',
  text: t('navbar.projects'),
  href: '/projects/list',
  iconAdjust: '20px'
});

export const rejectedProjectItem = (t: TFunction): Item => ({
  icon: RequestQuoteOutlinedIcon,
  key: 'projects',
  text: t('navbar.projects_rejected'),
  href: '/rejected-projects/list',
  iconAdjust: '20px'
});

export const assistanceItem = (t: TFunction): Item => ({
  icon: assistanceIcon,
  key: 'assistances',
  text: t('navbar.assistances'),
  href: '/assistances/list',
  iconAdjust: '20px'
});
export const assistanceRejectedItem = (t: TFunction): Item => ({
  icon: servicesIcon,
  key: 'assistances-rejected',
  text: t('navbar.assistances_rejected'),
  href: '/assistance-rejected-list',
  iconAdjust: '20px'
});

export const assistedProviderItem = (t: TFunction): Item => ({
  icon: RequestQuoteOutlinedIcon,
  key: 'assisted-provider',
  text: t('navbar.assisted_providers'),
  href: '/assisted-provider-list',
  iconAdjust: '20px'
});

export const billItem = (t: TFunction): Item => ({
  icon: billIcon,
  key: 'bills',
  text: t('navbar.billing'),
  href: '/bill/list'
});

export const incomeItem = (t: TFunction): Item => ({
  icon: incomeIcon,
  key: 'incomes',
  text: t('navbar.incomes'),
  href: '/incomes/list'
});

export const expenseItem = (t: TFunction): Item => ({
  icon: expensesIcon,
  key: 'expenses',
  text: t('navbar.expenses'),
  href: '/expenses/list'
});

export const payrollItem = (t: TFunction): Item => ({
  icon: payrollIcon,
  key: 'payrolls',
  text: t('navbar.payrolls'),
  href: '/payrolls/list'
});

export const referralItem = (t: TFunction): Item => ({
  key: 'referrals',
  text: t('navbar.referrals'),
  href: '/profile/referral',
  boxProps: { pl: '0.7rem!important' },
  isHighlighted: true
});

export const helpItem = (t: TFunction): Item => ({
  icon: SupportIcon,
  key: 'help',
  text: t('navbar.help'),
  href: '/contact-us'
});

export const profileItem = (t: TFunction): Item => ({
  key: 'profile',
  text: t('navbar.profile'),
  icon: servicesIcon,
  href: '/profile/details'
});

export const providersItem = (t: TFunction): Item => ({
  key: 'assisted-provider',
  text: t('navbar.providers'),
  icon: clientsIcon,
  href: '/providers/list'
});

export const logoutItem = (t: TFunction, handleLogout: () => void): Item => ({
  key: 'logout',
  text: t('common.logout'),
  icon: ExitToAppIcon,
  handleFunction: handleLogout
});

export const userDataItem = (
  userData: string,
  handleFunction: () => void,
  popoverItems: Item[]
): Item => ({
  key: 'user-data',
  text: userData,
  avatar: userData,
  handleFunction: handleFunction,
  popoverItems: popoverItems
});

export const dashboardSection = (items: Item[]) => ({
  title: '',
  divider: false,
  key: 'dashboard-section',
  items: items
});

export const projectSection = (t: TFunction, items: Item[]) => ({
  title: t('navbar.section.my_proyects'),
  key: 'projects-section',
  items: items
});

export const financialSection = (t: TFunction, items: Item[]) => ({
  title: t('navbar.section.my_finance'),
  key: 'my-finance-section',
  items: items
});

export const payrollSection = (t: TFunction, items: Item[]) => ({
  title: t('navbar.section.social_security'),
  key: 'payrolls-section',
  items: items
});

export const referralSection = (items: Item[]) => ({
  title: '',
  key: 'referrals_section',
  items: items
});

export const userDataSection = (items: Item[]) => ({
  key: 'user-data-section',
  title: '',
  divider: false,
  items: items,
  isCollapsible: true
});

export const advancesSection = (items: Item[]) => ({
  key: 'advances-section',
  title: translate('common:navbar.section.advances'),
  divider: false,
  items: items
});

export const assistancesSection = (items: Item[]) => ({
  key: 'assistances-section',
  title: translate('common:navbar.section.assistances'),
  divider: false,
  items: items
});

export const operationProjectsSection = (items: Item[]) => ({
  key: 'operation-projects',
  title: translate('common:navbar.section.projects'),
  divider: false,
  items: items
});

export const reportsSeccion = (t: TFunction): Item => ({
  icon: incomeIcon,
  key: 'reports',
  text: t('navbar.reports'),
  href: '/reports'
});
