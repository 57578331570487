import { ReactComponent as BurguerIcon } from '@../../../public/static/svg/icons/menu/burguer.svg';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  DialogContentText,
  Drawer,
  Hidden,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled, ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { Theme } from '@mui/system';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavbarContext } from '../../contexts/NavBarButtonContext';
import { useUser } from '../../contexts/UserContext';
import { useUserContext } from '../../hooks/useUserContext';
import useLoginAsOperations from '../../views/pages/Public/Auth/hooks/useLoginAsOperations';
import ConfirmDialog from '../Modals/ConfirmDialog';
import mdTheme from '../Theme';
import ItemList from './ItemList';
import Logo from './Logo';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const LgDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9)
      }
    })
  }
}));

type DashboardContentProps = {};
const Menu: React.FC<DashboardContentProps> = ({ children }) => {
  useUserContext();
  const user = useUser();
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(!mdDown);
  const menuScrollRef = React.useRef<HTMLInputElement>(null);
  const navBarContext = useNavbarContext();
  const atLeastSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const { t } = useTranslation('common');
  const [openLoginModal, setOpenModalLogin] = React.useState(false);
  const { mutate: loginAsOperations, isLoading: isLoadingLoginOperations } =
    useLoginAsOperations();

  const toggleDrawer = React.useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  useEffect(() => {
    setOpen(!mdDown);
  }, [mdDown]);

  const handleLoginModalClose = () => {
    setOpenModalLogin(false);
  };

  const handleLoginModalOpen = () => {
    setOpenModalLogin(true);
  };
  const handleLoginConfirm = () => {
    if (user?.currentUser.operation_email) {
      loginAsOperations({
        operations_email: user?.currentUser.operation_email
      });
    }
  };

  const DrawerContent = React.useCallback(
    () => (
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        // @ts-ignore &&
        containerRef={(el) => (menuScrollRef.current = el)}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'space-between',
            px: [open ? 2 : 1],
            pt: [open ? 6 : 7],
            pb: [open ? 4 : 3]
          }}
        >
          <Logo />
          <IconButton
            onClick={atLeastSm ? toggleDrawer : navBarContext.handleChangeOpen}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {user?.currentUser.is_assistance && (
          <>
            <Alert severity="info">
              <Stack direction="column" gap={1}>
                {t('assistance.assisted_providers_list.assistance_session')}
                <Typography
                  component="strong"
                  variant="subtitle2"
                  onClick={handleLoginModalOpen}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { textDecoration: 'underline' }
                  }}
                >
                  {t('assistance.assisted_providers_list.back_to_operations')}
                </Typography>
              </Stack>
            </Alert>
          </>
        )}
        <ItemList isOpen={open} menuScrollRef={menuScrollRef} />
      </PerfectScrollbar>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [atLeastSm, navBarContext.handleChangeOpen, open, toggleDrawer]
  );

  return (
    <ThemeProvider theme={mdTheme}>
      <Box
        sx={{
          display: 'flex',
          background: mdTheme.palette.background.default,
          maxHeight: '100vh'
        }}
      >
        <CssBaseline />
        <AppBar
          position="absolute"
          open={open}
          sx={{
            background: 'transparent',
            boxShadow: 'none',
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <Toolbar
            sx={{
              pr: '20px',
              minHeight: '2.5rem!important'
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '30px',
                marginLeft: -1,
                marginTop: 1,
                color: mdTheme.palette.primary.main,
                background: mdTheme.palette.action.hover,
                borderRadius: '300px',
                ...(open && { display: 'none' })
              }}
            >
              <SvgIcon>
                <BurguerIcon />
              </SvgIcon>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Hidden smUp>
          <Drawer
            anchor="left"
            variant="temporary"
            onClose={navBarContext.handleChangeOpen}
            open={navBarContext.isNavOpen}
          >
            <DrawerContent />
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <LgDrawer
            variant="permanent"
            open={open}
            sx={{
              backgroundColor: (theme) => theme.palette.background.default
            }}
          >
            <DrawerContent />
          </LgDrawer>
        </Hidden>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => theme.palette.background.default,
            flexGrow: 1,
            height: '100vh',
            overflow: 'hidden'
          }}
        >
          {children}
        </Box>
      </Box>
      <ConfirmDialog
        isOpen={openLoginModal}
        onConfirm={handleLoginConfirm}
        onDecline={handleLoginModalClose}
        action=" "
        isLoading={isLoadingLoginOperations}
        title={t('common.confirm.action_confirmation', {
          action: t(
            'assistance.assisted_providers_list.return_to_operations_title'
          )
        })}
        contentChildren={
          <DialogContentText textAlign="left">
            <Stack direction="column" rowGap={3}>
              {t(
                'assistance.assisted_providers_list.return_to_operations_description'
              )}
            </Stack>
          </DialogContentText>
        }
      />
    </ThemeProvider>
  );
};

export default Menu;
