import { Typography } from '@mui/material';
import { Box } from '@mui/system';

type Props = {
  fileType?: string;
};
export default function FileSheetIcon(
  { fileType = 'PDF' }: Props,
  { ...props }
) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <img
        src={`/static/svg/icons/file_sheet_icon.svg`}
        alt="FileSheetIcon"
        {...props}
        style={{
          position: 'absolute',
          zIndex: 1
        }}
      />
      <Box
        height="0.625rem"
        width="0.938rem"
        bgcolor="#DBF2FC"
        display="flex"
        justifyContent="center"
        alignItems="center"
        ml={2}
        zIndex={2}
      >
        <Typography variant="caption" fontWeight="600" fontSize="0.375rem">
          {fileType}
        </Typography>
      </Box>
    </Box>
  );
}
