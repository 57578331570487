import { Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { FC } from 'react';
import { OnboardingStepperIcon } from '../../../../../components/Icons/OnboardingStepperIcon';
import { ElephantIcon, RightMobileDecorator } from '../Stylings';

type Props = {
  activeStep: number;
  steps: string[];
};
const MobileTopStepper: FC<Props> = ({ activeStep, steps }) => {
  return (
    <Stack direction="column" alignItems="center" pt={2}>
      <Stack direction="row" alignItems="center" pb={2}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={null}
          sx={{
            position: 'absolute',
            left: '0.75rem',
            '& .MuiStep-alternativeLabel': {
              paddingRight: '0.25rem',
              paddingLeft: '0.25rem'
            }
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={OnboardingStepperIcon} />
            </Step>
          ))}
        </Stepper>
        <ElephantIcon />
      </Stack>
      <Stack direction="column">
        <Typography
          variant="body1"
          align="center"
          color="common.white"
          fontWeight="bold"
        >
          Ayúdanos completando
        </Typography>
        <Typography
          variant="body1"
          align="center"
          color="common.white"
          fontWeight="bold"
        >
          algunos datos
        </Typography>
      </Stack>
      <RightMobileDecorator item xs={12} />
    </Stack>
  );
};

export default MobileTopStepper;
