import { currencyValue } from './regex';

export function numberRange(len: number) {
  return Array.from({ length: len }, (_, i) => i + 1);
}

export const paymentDaysGenerator = (
  t: Function,
  arrayLength?: number,
  text?: any
) => {
  return Array.from({ length: arrayLength || 31 }, (_, k) => {
    return {
      value: k + 1,
      label: text
        ? t(text, { number: k + 1 })
        : `${t('common.all_the')} ${k + 1}`
    };
  });
};

export const percentageNumberGenerator = (
  initialValue: number,
  step: number = 1
) =>
  Array.from({ length: Math.floor(100 / step) }, (_, k) => {
    return {
      value: (k + 1) * step,
      label: `${(k + 1) * step}% (${currencyValue(
        (initialValue * ((k + 1) * step)) / 100
      )})`
    };
  });

export const numberGenericOptionGenerator = (length: number) =>
  Array.from({ length: length }, (_, k) => `${k + 1}`);

// Native types can be found in the following link:
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const acceptedFileExtensionsListGenerator = (
  extensions: FileExtensions[]
) => {
  const currentFileExtensions = {
    pdf: 'application/pdf',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    png: 'image/png',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  };
  return extensions
    .map((extension) => currentFileExtensions[extension])
    .toString();
};

export function MaskCharacter(str: string, mask: string, n = 4) {
  // Slice the string and replace with
  // mask then add remaining string
  return ('' + str).slice(0, -n).replace(/./g, mask) + ('' + str).slice(-n);
}
