import { tableCellClasses, TableRow } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';

// Colors that are to be used in the mdTheme multiple times.
const themeColors = {
  background: '#FFFFFF',
  primary: '#002432',
  secondary: '#93a141',
  action: '#0080B2',
  actionHover: '#DBF2FC',
  listHover: '#F7F8FD',
  error: '#E55057',
  inputGrey: '#DFE1E9',
  contrastText: '#717584',
  activeGreen: '#0DBC7D',
  lightGreen: '#ECEFD7'
};

// Refer to https://mui.com/customization/default-theme/
const mdTheme = createTheme({
  palette: {
    factcilGrey: {
      main: themeColors.inputGrey
    },
    primary: {
      main: themeColors.primary
    },
    secondary: {
      main: themeColors.secondary,
      contrastText: themeColors.contrastText,
      light: themeColors.lightGreen
    },
    error: {
      main: themeColors.error
    },
    action: {
      active: themeColors.action,
      hover: themeColors.actionHover
    },
    info: {
      main: themeColors.action
    },
    background: {
      default: themeColors.background
    },
    list: {
      hover: themeColors.listHover
    },
    form: {
      checkedCheckBox: themeColors.activeGreen
    },
    success: {
      main: themeColors.activeGreen
    }
  },
  typography: {
    fontFamily:
      '"Lexend", -apple-system, BlinkMacSystemFont, "Helvetica", "Arial", sans-serif'
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiOutlinedInput-root': {
            borderRadius: '0.5rem',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.active
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.factcilGrey.main
            }
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.active
            }
          },
          '& .MuiOutlinedInput-root.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.error.main
            }
          },
          '& .MuiFormLabel-root.Mui-focused': {
            color: theme.palette.action.active
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: theme.palette.error.main
          },
          '& .Mui-disabled': {
            '& .MuiOutlinedInput-input': {
              borderRadius: '0.5rem',
              backgroundColor: theme.palette.list.hover
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.factcilGrey.main
            }
          }
        })
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: "3rem",
          fontFamily: "'Ubuntu', sans-serif",
          fontStyle: "normal",
          boxShadow: "none",
          borderRadius: '2.5rem',
          textTransform: 'none',
          '&:hover': {
            boxShadow: "none",
            backgroundColor: themeColors.action,
          },
          '&.hight-light': {
            boxShadow: "none",
            backgroundColor: themeColors.background,
            '&:hover': {
              boxShadow: "none",
              borderColor: themeColors.action,
              color: themeColors.action,
              padding: '11px 24px 13px'
            }
          }
        },
        outlined: ({ theme }) => ({
          borderColor: theme.palette.grey[300],
          '&:hover': {
            borderColor: themeColors.action,
            backgroundColor: themeColors.background,
            color: themeColors.action
          }
        })
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '0.5rem'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {}
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.hightlighted': {
            '&>.MuiBox-root': {
              backgroundColor: themeColors.listHover,
              transition: 'all 0.300s ease-in-out'
            }
          },
          '&:hover': {
            backgroundColor: 'transparent',
            '&>.MuiBox-root': {
              backgroundColor: themeColors.inputGrey,
              transition: 'all 0.300s ease-in-out'
            },
            '&.Mui-selected': {
              '&>.MuiBox-root': {
                backgroundColor: 'transparent'
              }
            }
          },

          '&>.MuiBox-root': {
            display: 'flex',
            alignItems: 'center',
            borderRadius: '8px',
            padding: '5px 12px 5px 0px',
            width: '100%'
          },

          '&.Mui-selected': {
            ':hover': {
              backgroundColor: 'transparent'
            },
            backgroundColor: 'transparent',
            '& .MuiTypography-root': {
              fontWeight: 600,
              '&::after': {
                content: '" "',
                border: `10px solid ${themeColors.action}`,
                position: 'absolute',
                bottom: '30%',
                marginLeft: '-15px',
                borderRadius: '5px',
                left: '0'
              }
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-clearIndicator': {
            '& .MuiSvgIcon-root': {
              color: '#002432'
            }
          }
        }
      }
    }
  }
});

export const TableBodyRow = styled(TableRow)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: `1px solid ${theme.palette.factcilGrey.main}`,
    borderTop: `1px solid ${theme.palette.factcilGrey.main}`,
    '&:first-of-type': {
      borderLeft: `1px solid ${theme.palette.factcilGrey.main}`,
      borderTopLeftRadius: '0.5rem',
      borderBottomLeftRadius: '0.5rem'
    },
    '&:last-child': {
      borderRight: `1px solid ${theme.palette.factcilGrey.main}`,
      borderTopRightRadius: '0.5rem',
      borderBottomRightRadius: '0.5rem'
    }
  },
  '&:hover': {
    background: theme.palette.list.hover,
    [`& .${tableCellClasses.root}`]: {
      borderColor: theme.palette.list.hover
    }
  }
}));

declare module '@mui/material/styles' {
  interface Palette {
    factcilGrey: Palette['primary'];
    list: Palette['action'];
    form: Palette['primary'];
  }

  interface PaletteOptions {
    factcilGrey?: {};
    list?: {};
    form?: {};
  }
}

export default mdTheme;
