import { t } from 'i18next';

export const identificationChoices = ['CC', 'CE'];

export const sexChoices = ['M', 'F'];

export const bankAccountTypeChoices = ['SAVINGS_ACCOUNT', 'CURRENT_ACCOUNT'];

export const timeOptions = () => [
  { value: '30', label: `30 ${t('common:common.days')}` },
  { value: '60', label: `60 ${t('common:common.days')}` },
  { value: '90', label: `90 ${t('common:common.days')}` },
  { value: 0, label: `${t('common:common.other')}` }
];
