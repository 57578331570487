import { Control, Controller } from 'react-hook-form';
import { Autocomplete, AutocompleteType } from '../Autocomplete';

interface RHFAutocompleteProps extends AutocompleteType {
  control: Control<any>;
  name: string;
  defaultValue?: any;
}

const RHFAutocomplete = (rhfAutocomplete: RHFAutocompleteProps) => {
  const {
    name,
    control,
    autocompleteProps,
    textFieldProps,
    error,
    defaultValue
  } = rhfAutocomplete;
  const { onChange, ...autocompletePropsRest } = autocompleteProps;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Autocomplete
          error={error}
          textFieldProps={textFieldProps}
          autocompleteProps={{
            ...autocompletePropsRest,
            value: field.value,
            onChange: onChange ? onChange : (_, data) => field.onChange(data)
          }}
        />
      )}
    />
  );
};

export default RHFAutocomplete;
