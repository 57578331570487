export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const phoneWithPrefixRegExp =
  /^((\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const currencyValue = (number: any) => {
  return `$${new Intl.NumberFormat('de-DE').format(number)}`;
};

// eslint-disable-next-line no-useless-escape
export const addressRegExp = /^([A-Za-z0-9\u00F1\u00D1])[A-Za-z0-9\:\-\.\,\;\°\#\s\u00F1\u00D1]{6,38}[A-Za-z0-9\:\-\.\,\;\°\#\u00F1\u00D1]+[\s]*$/;

export const toTitle = (text: string) => {
  if (text) return text.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase());
  return '';
};
